import styled from "styled-components"
import Icon from "assets/images/gallery/icon_carousel.svg"

const CarouselIcon = () => {
  return <StyledImg src={Icon} />
}

export default CarouselIcon

const StyledImg = styled.img`
  width: 100%;
`
