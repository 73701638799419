import * as THREE from "three"
import { useRef } from "react"
import { useTexture } from "@react-three/drei"
import { useGLTF } from "utils/hooks/useGLTF"
import { useStore } from "state/store"
import BuildingModel from "assets/models/building.gltf"
import GlassModel from "assets/models/nomap.gltf"

export default function Building() {
  const { nodes } = useGLTF(BuildingModel)
  const nodesArr = Object.values(nodes)
  const textureNames = []
  nodesArr.map(mesh => textureNames.push(mesh.name.split("-")[1]))

  return (
    <group>
      <GlassLoader />
      {nodesArr.map((mesh, index) => (
        <BakedMeshElement key={index} mesh={mesh} />
      ))}
    </group>
  )
}

function GlassLoader() {
  const { nodes } = useGLTF(GlassModel)

  return (
    <mesh
      position={nodes.glass.geometry.position}
      geometry={nodes.glass.geometry}
      scale={1}
      material={
        new THREE.MeshPhysicalMaterial({
          flatShading: true,
          transparent: true,
          transmission: 1,
          reflectivity: 1,
          roughness: 0,
          ior: 4
        })
      }
    />
  )
}

function BakedMeshElement({ mesh }) {
  const meshRef = useRef()
  const textureName = mesh.name.split("-")[1]
  const floorNum = parseInt(mesh.name.split("-")[0])
  const activeFloor = useStore(state => state.activeFloor)

  const isActive = activeFloor === 13 - floorNum

  const diffuse = useTexture(
    `${process.env.PUBLIC_URL}/assets/textures/${textureName}_d.jpg`
  )

  diffuse.encoding = THREE.sRGBEncoding

  diffuse.flipY = false
  const BAKED_MAT = new THREE.MeshBasicMaterial({
    map: diffuse
  })

  const HIGHLIGHT_MAT = new THREE.MeshStandardMaterial({
    flatShading: true,
    color: "#111702"
  })

  return (
    <>
      <mesh
        ref={meshRef}
        position={mesh.position}
        scale={1}
        geometry={mesh.geometry}
        material={isActive ? HIGHLIGHT_MAT : BAKED_MAT}
      />
    </>
  )
}
