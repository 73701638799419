import React from "react"
import styled from "styled-components"
import { device } from "theme/device"

export default function NavButton({ className, children, onClick }) {
  return (
    <NavBtn className={className} onClick={onClick}>
      {children}
    </NavBtn>
  )
}

const NavBtn = styled.div`
  width: 4.5em;
  height: 4.5em;
  opacity: 0.8;
  cursor: pointer;
  transition: 0.3s ease-in-out;
  &:hover {
    opacity: 1;
  }
  @media ${device.mobileL} {
    width: 2em;
    height: 2em;
  }
`
