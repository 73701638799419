import { Pannellum } from "pannellum-react"

export default function Viewer360({ src }) {
  return (
    <Pannellum
      width="100%"
      height="100%"
      image={src}
      pitch={10}
      yaw={180}
      hfov={110}
      autoLoad
      showZoomCtrl={false}
    />
  )
}
