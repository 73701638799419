import React, { useRef, useState, useEffect } from "react"
import ReactPlayer from "react-player"
import styled from "styled-components"
import Cover from "./Cover"
import { useStore } from "state/store"
import { motion } from "framer-motion"

export default function VideoPlayer({ url, isVisible, playing, onEnd }) {
  const player = useRef()
  const [ready, setReady] = useState(false)
  const setIsVideoReady = useStore(state => state.setIsVideoReady)
  const orbitIndex = useStore(state => state.orbitIndex)

  useEffect(() => {
    if (!isVisible && ready) {
      setTimeout(() => {
        player.current.seekTo(0)
      }, 300)
    }
  }, [ready, isVisible])

  useEffect(() => {
    setIsVideoReady(ready)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ready])

  const container = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        duration: 2
      }
    }
  }

  return (
    <Holder visible={isVisible}>
      <VideoPlaceholder
        src={`${process.env.PUBLIC_URL}/assets/images/placeholders/seq${orbitIndex}.jpg`}
      />
      <Cover>
        <motion.div variants={container} initial="hidden" animate="show">
          <ReactPlayer
            ref={player}
            url={url}
            width="100%"
            height="100%"
            playing={playing}
            onEnded={onEnd}
            onReady={setReady}
            playsinline={true}
            style={{ position: "relative", zIndex: 1 }}
            config={{
              file: { attributes: { poster: "noposter" } }
            }}
          />
        </motion.div>
      </Cover>
    </Holder>
  )
}

const Holder = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: ${props => (props.visible ? "block" : "none")};
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  overflow: hidden;
`

const VideoPlaceholder = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
`
