import { useMemo } from "react"
import styled from "styled-components"

import { useStore } from "state/store"
import { device } from "theme/device"
import ApartmentCard from "components/ApartmentCard"

import WillowWalksIcon from "assets/images/share/willow_walks.svg"
import { FooterContent } from "./Share"

export default function PrintShare() {
  const favourites = useStore(state => state.favourites)

  const favs = useMemo(() => {
    let arr = Array.from(favourites)
    let newArr = []
    while (arr.length) {
      newArr.push(arr.splice(0, 2))
    }
    return newArr
  }, [favourites])

  return (
    <Holder id="print-share-page">
      {favs.map((items, index) => {
        return (
          <div key={index}>
            <Header>
              <h1>your favourites</h1>
              <img src={WillowWalksIcon} alt="" />
            </Header>
            <ApartCardList>
              {items?.map((apartment, sIndex) => {
                return (
                  <ApartCard
                    key={sIndex}
                    apartment={apartment}
                    isShare={true}
                  />
                )
              })}
            </ApartCardList>
            <Footer />
            {items.length === 2 && favs.length === index + 1 && (
              <>
                <Header>
                  <h1>your favourites</h1>
                  <img src={WillowWalksIcon} alt="" />
                </Header>
                <Footer />
              </>
            )}
          </div>
        )
      })}
    </Holder>
  )
}

const Holder = styled.div`
  position: fixed;
  top: 9999px;
  left: 0;
  z-index: 1;
  width: 1400px;
  background-color: white;
`

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: black;
  h1 {
    flex: 1;
    color: #f1f1f1;
    text-transform: uppercase;
    font-family: LouvetteDeck-Regular;
    font-size: 3.2em;
    letter-spacing: 0.1em;
    margin: 0 1em 0 1em;
    padding: 0;
    @media ${device.pad} {
      font-size: 5vw;
    }
  }
  img {
    width: 30%;
    max-width: 300px;
  }
`

const ApartCardList = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`

const ApartCard = styled(ApartmentCard)`
  width: 100%;
`

const FooterHolder = styled.div`
  width: 100%;
  background-color: #efefef;
  padding: 52px 3em 4em 3em;
  font-family: Futura-Book;
  color: black;
  font-size: 1.5em;
  p {
    font-size: 2em;
    line-height: 150%;
    letter-spacing: 0.1em;
    margin: 0;
    padding: 0;
    text-transform: uppercase;
  }
  div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 0.5em;
    gap: 2em;
    div {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      gap: 0.5em;
      p {
        font-size: 0.9rem;
        line-height: 100%;
        margin: 0;
        padding: 0;
      }
      img {
        width: 15rem;
      }
    }
  }
`

const Footer = () => {
  return (
    <FooterHolder>
      <FooterContent />
    </FooterHolder>
  )
}
