import { createRef } from "react"
import styled from "styled-components"

import BuildingExplorer from "components/BuildingExplorer"
import PageTransitionHolder from "components/PageTransitionHolder"
import ApartmentCardOverlay from "components/BuildingExplorer/UI/ApartmentCardOverlay"
import ApartmentFilter from "components/ApartmentFilter"

export default function ApartmentFinder() {
  return (
    <PageTransitionHolder>
      <Holder>
        <BuildingExplorer
          baseState={{
            sections: 12,
            pages: 12,
            zoom: 1,
            top: createRef(),
            factor: 0.6
          }}
        />
        <ApartmentFilter />
        <ApartmentCardOverlay />
      </Holder>
    </PageTransitionHolder>
  )
}

const Holder = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`
