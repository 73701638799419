import styled from "styled-components"
import ArrowIcon from "components/Icons/ArrowIcon"

export default function NextButton(props) {
  const { onClick, className } = props
  return (
    <Button className={className} onClick={onClick}>
      <ArrowIcon direction="right" />
    </Button>
  )
}

const Button = styled.button`
  width: 8em;
  height: fit-content;
  border: none;
  outline: none;
  background-color: transparent;
  cursor: pointer;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.3em;
  transition: opacity 0.2s ease-out;
  &:hover {
    opacity: 0.5;
  }
`
