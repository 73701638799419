import styled from "styled-components"
import { AnimatePresence } from "framer-motion"

import { useStore } from "state/store"

import { LOCATION_TAB_DATA } from "dataset/location"

import PageTransitionHolder from "components/PageTransitionHolder"
import IconButton from "components/Buttons/IconButton"
import TravelTime from "components/TravelTime"

import ClockIcon from "assets/images/icons/clock.svg"

export default function Location() {
  const openTimeLocation = useStore(state => state.openTimeLocation)
  const setOpenTimeLocation = useStore(state => state.setOpenTimeLocation)
  const currentTabLocation = useStore(state => state.currentTabLocation)
  const setCurrentTabLocation = useStore(state => state.setCurrentTabLocation)

  const handleTabClick = tab => {
    setCurrentTabLocation(tab.id)
  }

  return (
    <PageTransitionHolder>
      <Holder currentTab={currentTabLocation}>
        <TimeControl
          icon={ClockIcon}
          onClick={() => {
            setOpenTimeLocation(true)
          }}
        />
        <Tabs>
          {LOCATION_TAB_DATA.map((tab, index) => (
            <Tab
              key={index}
              active={currentTabLocation === tab.id}
              onClick={() => {
                handleTabClick(tab)
              }}
            >
              {tab.label}
            </Tab>
          ))}
        </Tabs>
        {LOCATION_TAB_DATA[currentTabLocation].component}
        <AnimatePresence>
          {openTimeLocation && <TravelTime visible={openTimeLocation} />}
        </AnimatePresence>
      </Holder>
    </PageTransitionHolder>
  )
}

const Holder = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background-color: ${props =>
    props.currentTab === 0 ? "#f3f2f0" : "#164729"};
`

const Tabs = styled.div`
  width: 4em;
  height: 100%;
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 2;
  display: flex;
  flex-direction: column;
  gap: 0.2em;
`

const Tab = styled.div`
  position: relative;
  flex: 1;
  display: flex;
  justify-content: center;
  cursor: pointer;
  background-color: ${props => (props.active ? "#929B49" : "#6A7F3C")};
  letter-spacing: 0;
  padding: 0.2rem;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease-out;
  writing-mode: vertical-rl;
  text-orientation: mixed;
  font-family: LouvetteDeck-Regular;
  font-size: 3vh;
  letter-spacing: 0.1em;
  color: #ffffff;
  &:hover {
    opacity: 0.95;
  }
`

const TimeControl = styled(IconButton)`
  position: absolute;
  bottom: 1em;
  right: 5em;
  z-index: 1;
  width: 4em;
  height: 4em;

  background: #d3b594;
  box-shadow: 0 0 0 0 rgba(255, 255, 255, 1);
  animation: pulse-white 2s infinite;

  @keyframes pulse-white {
    0% {
      transform: scale(0.85);
      box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
    }

    70% {
      transform: scale(1);
      box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
    }

    100% {
      transform: scale(0.85);
      box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    }
  }
`
