import PrevIcon from "assets/images/home/previous.svg"
import NextIcon from "assets/images/home/next.svg"
import styled from "styled-components"

export const ArrowLeft = () => <Icon src={PrevIcon} alt="previous" />
export const ArrowRight = () => <Icon src={NextIcon} alt="next" />

const Icon = styled.img`
  width: 100%;
  height: 100%;
`
