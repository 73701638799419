import styled from "styled-components"
import Icon from "assets/images/gallery/icon_grid.svg"

const GridIcon = () => {
  return <StyledImg src={Icon} />
}

export default GridIcon

const StyledImg = styled.img`
  width: 100%;
`
