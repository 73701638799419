import { Canvas } from "@react-three/fiber"
import { memo } from "react"
import { Suspense } from "react"
import { FloorPlates } from "../Floors/FloorScroller"

export const FloorsScene = memo(function FloorsScene({
  scrollArea,
  baseState
}) {
  return (
    <Canvas
      onCreated={state => state.events.connect(scrollArea.current)}
      linear
      camera={{
        zoom: baseState.zoom,
        fov: 55,
        position: [0, 0, 20]
      }}
      gl={{
        antialias: true
      }}
      dpr={1.5}
    >
      <ambientLight intensity={0.3} />
      <pointLight intensity={0.7} position={[-2, 5, 0]} />
      <pointLight intensity={0.3} position={[-10, 5, 15]} />
      <pointLight intensity={0.7} position={[0, 5, 0]} />
      <Suspense fallback={null}>
        <FloorPlates baseState={baseState} />
      </Suspense>
    </Canvas>
  )
})
