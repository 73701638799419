import styled from "styled-components"
import { AnimatePresence, motion } from "framer-motion"
import { useStore } from "state/store"
import ApartmentCard from "components/ApartmentCard"

function ApartmentCardOverlay() {
  const activeApartment = useStore(state => state.activeApartment)
  const setActiveApartment = useStore(state => state.setActiveApartment)
  // const plotNumber = useMemo(() => {
  //   const result = activeApartment
  //     ? helpers.returnPlotNumber(activeApartment)
  //     : null
  //   return result
  // }, [activeApartment])

  return (
    <AnimatePresence>
      {/* {true && ( */}
      {activeApartment && (
        <CardDialog
          transition={{ duration: 0.5, delay: 0.25 }}
          initial={{ opacity: 0, y: 0 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: 0 }}
          onClick={() => {
            setActiveApartment(null)
          }}
        >
          <ApartmentCard apartment={activeApartment} />
        </CardDialog>
      )}
    </AnimatePresence>
  )
}

export default ApartmentCardOverlay

const CardDialog = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3;
  width: 100%;
  height: 100%;
  display: flex;
  padding: 1rem 6rem;
  justify-content: center;
  align-items: center;
  background-color: #000000aa;
`
