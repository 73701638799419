const degree = {
  top: "-90deg",
  right: "0",
  bottom: "90deg",
  left: "180deg"
}

const ArrowIcon = ({ color = "#ffffff", direction = degree.right }) => {
  return (
    <svg
      width="108"
      height="16"
      viewBox="0 0 108 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        width: "100%",
        transform: `rotateZ(${degree[direction]})`
      }}
    >
      <path
        fill={color}
        d="M99.4875 15.559C102.24 13.4688 104.994 11.3567 107.385 9.02016C107.717 8.69539 107.878 8.17338 107.385 7.86387C103.254 5.26468 99.723 2.07916 94.9628 0.107699C93.7422 -0.397972 92.6567 1.01442 93.8743 1.519C98.4694 3.4229 101.874 6.51142 105.863 9.02016C105.863 8.63436 105.863 8.24966 105.863 7.86387C103.472 10.2004 100.717 12.3125 97.9654 14.4027C96.9818 15.1492 98.5053 16.3055 99.4875 15.559Z"
      />
      <path
        fill={color}
        d="M106.802 7.08031C71.4237 7.02364 35.8534 4.38081 0.648445 8.74447C-0.488083 8.88506 -0.00254059 10.4599 1.12209 10.3204C36.1557 5.97849 71.5987 8.65838 106.802 8.71505C107.954 8.71614 107.953 7.0814 106.802 7.08031Z"
      />
    </svg>
  )
}

export default ArrowIcon
