import styled, {
  ThemeProvider as StyledComponentsThemeProvider,
  createGlobalStyle
} from "styled-components"
import { normalize } from "polished"
import theme from "./theme"
import Div100vh from "react-div-100vh"

// Swiper modules imports are same as usual
import "swiper/swiper.min.css" // core Swiper
import "swiper/modules/navigation/navigation.min.css" // Navigation module
import "swiper/modules/pagination/pagination.min.css" // Pagination module
import "swiper/modules/scrollbar/scrollbar.min.css" // Scrollbar module
import "swiper/modules/grid/grid.min.css" // Grid module

//Load primereact styles
import "./tailwind-light-theme.css"
import "primereact/resources/primereact.min.css"
import "primeicons/primeicons.css"

const GlobalStyle = createGlobalStyle`
  ${normalize()}

  body {
    color: ${theme.colors.primary};
    font-Size: 16px;
    font-family: "LouvetteDeck-Regular";
    padding: 0;
    margin: 0;
    overflow: hidden;
    background-color: #D0CFCF;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  * {
    box-sizing: border-box;
    user-select: none;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: transparent;
  }

  a {
    text-decoration: none;    
  }

  *::-webkit-scrollbar {
    width: 7px;
    height: 10px;
  }
  *::-webkit-scrollbar-track {
    background-color: rgb(235, 235, 235);
    border-radius: 0px;
  }
  *::-webkit-scrollbar-thumb {
    height: 92px;
    background-color: #1D3D27 ;
    border-radius: 0px;
  }
`

const Container = styled(Div100vh)`
  width: 100vw;
`

export default function ThemeProvider({ children }) {
  return (
    <StyledComponentsThemeProvider theme={theme}>
      <GlobalStyle />
      <Container>{children}</Container>
    </StyledComponentsThemeProvider>
  )
}
