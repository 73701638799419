import { config } from "@react-spring/core"
import { animated, useTransition } from "@react-spring/web"
import { useRef } from "react"

function AnimatedPath({ data, isOpen, setIndex, index }) {
  const ref = useRef()
  const pathRef = useRef()

  const ind = Math.floor(Math.random(6) * 3)

  const transitions = useTransition(isOpen, {
    from: { x: 0 },
    enter: { x: 1 },
    leave: { x: 0 },
    reverse: isOpen,
    delay: isOpen ? 600 : 0,
    onStart: () =>
      isOpen && setIndex(ind === index ? Math.floor(Math.random(6) * 3) : ind),
    config: isOpen
      ? config.molasses
      : { mass: 1, tension: 210, friction: 20, clamp: true }
  })

  return transitions(
    ({ x }, item) =>
      item && (
        <div
          style={{
            position: "absolute",
            zIndex: 9,
            right: 0,
            bottom: 0,
            margin: 20,
            opacity: 0.6,
            maxWidth: "100%",
            height: "30%"
          }}
        >
          <animated.svg
            ref={ref}
            width="100%"
            height="100%"
            style={{
              width: "100%",
              height: "100%"
            }}
            viewBox={data.viewBox}
            strokeWidth="2"
            fill="none"
            stroke="#F0E5DA"
            strokeLinecap="round"
            strokeLinejoin="round"
            // strokeDasharray={length}
            strokeDasharray={data.length}
            // strokeDashoffset={x.to(x => (1 - x) * length)}
            strokeDashoffset={x.to(x => (1 - x) * data.length)}
          >
            <path ref={pathRef} d={data.path} />
          </animated.svg>
        </div>
      )
  )
}

function AnimatedPathContainer({ data, isOpen, setIndex, index }) {
  return (
    <AnimatedPath
      data={data}
      isOpen={isOpen}
      setIndex={setIndex}
      index={index}
    />
  )
}

export default AnimatedPathContainer
