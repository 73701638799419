import { MAPPED_PROPS } from "api/typeMappers"

const { useStore } = require("state/store")

export const useAvailableApartments = () => {
  const apartments = useStore(state => state.apartments)
  //Filters
  const priceRange = useStore(state => state.priceRange)
  const selectedBedrooms = useStore(state => state.selectedBedrooms)
  const selectedAspects = useStore(state => state.selectedAspects)

  let filteredApartments = Array.from(apartments)

  // // Only deal with Available apartments
  let availableApartments = filteredApartments.filter(
    ap => ap[MAPPED_PROPS.STATUS] === "0"
  )

  //Filter to price range
  availableApartments = availableApartments.filter(
    apartment =>
      apartment[MAPPED_PROPS.PRICE] >= priceRange[0] &&
      apartment[MAPPED_PROPS.PRICE] <= priceRange[1]
  )

  //Filter to bedrooms
  let hasBedroomFilter = false

  for (let i = 0; i < selectedBedrooms.length; i++) {
    if (selectedBedrooms[i]) {
      hasBedroomFilter = true
      break
    }
  }

  if (hasBedroomFilter) {
    availableApartments = availableApartments.filter(
      apartment =>
        Number(apartment["Size (Bed/Person)"].charAt(0)) ===
          (selectedBedrooms[0] ? 1 : 0) ||
        Number(apartment["Size (Bed/Person)"].charAt(0)) ===
          (selectedBedrooms[1] ? 2 : 0) ||
        Number(apartment["Size (Bed/Person)"].charAt(0)) ===
          (selectedBedrooms[2] ? 3 : 0) ||
        Number(apartment["Size (Bed/Person)"].charAt(0)) ===
          (selectedBedrooms[3] ? 4 : 0)
    )
  }

  // //Filter to aspects
  let hasAspectFilter = false
  for (let i = 0; i < selectedAspects.length; i++) {
    if (selectedAspects[i]) {
      hasAspectFilter = true
      break
    }
  }

  if (hasAspectFilter) {
    availableApartments = availableApartments.filter(
      apartment =>
        apartment["Aspect"].includes(selectedAspects[0] ? "North" : "-") ||
        apartment["Aspect"].includes(selectedAspects[1] ? "East" : "-") ||
        apartment["Aspect"].includes(selectedAspects[2] ? "South" : "-") ||
        apartment["Aspect"].includes(selectedAspects[3] ? "West" : "-")
    )
  }

  return availableApartments
}

export const useAvailableApartmentsByTypes = () => {
  const availableApartments = useAvailableApartments()
  const data = {}
  const apartmentsByTypeObj = {}
  const apartmentsByPlotObj = {}

  availableApartments.map(
    ap => (apartmentsByTypeObj[ap[MAPPED_PROPS.TYPE]] = ap)
  )
  availableApartments.map(
    ap => (apartmentsByPlotObj[formatPlotName(ap[MAPPED_PROPS.PLOT])] = ap)
  )

  // Combine apartments that have a mirrored verison, AKA 'handed'
  // these will be mirrored in geometry instead
  const removeHandedSet = availableApartments.filter(
    ap => !ap[MAPPED_PROPS.TYPE].includes("H")
  )

  const typeSet = new Set(removeHandedSet.map(ap => ap[MAPPED_PROPS.TYPE]))

  data.typeArr = Array.from(typeSet)
  data.apartmentsByType = apartmentsByTypeObj
  data.apartmentsByPlot = apartmentsByPlotObj

  return data
}
export const useApartmentsByTypes = () => {
  const apartments = useStore(state => state.apartments)
  const data = {}
  const apartmentsByTypeObj = {}
  const apartmentsByPlotObj = {}

  apartments.map(ap => (apartmentsByTypeObj[ap[MAPPED_PROPS.TYPE]] = ap))
  apartments.map(
    ap => (apartmentsByPlotObj[formatPlotName(ap[MAPPED_PROPS.PLOT])] = ap)
  )
  apartments.forEach(ap => formatPlotName(ap[MAPPED_PROPS.PLOT]))

  const typeSet = new Set(apartments.map(ap => ap[MAPPED_PROPS.TYPE]))

  data.typeArr = Array.from(typeSet)
  data.apartmentsByType = apartmentsByTypeObj
  data.apartmentsByPlot = apartmentsByPlotObj

  return data
}

export const useAvailableByActiveType = () => {
  const activeType = useStore(state => state.activeType)
  const availableApartments = useAvailableApartments()

  return availableApartments.filter(ap => ap[MAPPED_PROPS.TYPE] === activeType)
    .length
}

export function formatPlotName(name) {
  // name.splice(name.length-2)

  return `${name?.slice(0, name.length - 2)}.${name?.slice(
    name.length - 2,
    name.length
  )}`
}
