import { useState, useEffect } from "react"
import styled from "styled-components"
import { AnimatePresence, motion } from "framer-motion"
import { Slider } from "primereact/slider"

import IconButton from "components/Buttons/IconButton"
import { priceFormatter } from "utils/convertDataFormat"
import { BEDS, DIRECTIONS } from "dataset/finder"
import { useStore } from "state/store"
import apartmentsFilterFn from "utils/apartmentsFilter.Fn"
import { useAvailableApartments } from "utils/hooks"

import { lightsOn } from "lightswarm/controller"

import FilterIcon from "assets/images/icons/icon-filter.svg"
import FilterCloseIcon from "assets/images/icons/icon-filter-close.svg"
import PriceIcon from "assets/images/icons/icon-price.svg"
import BedIcon from "assets/images/icons/icon-bed.svg"
import DirIcon from "assets/images/icons/icon-dir.svg"
import CircleIcon from "assets/images/icons/icon-circle.svg"
import RefreshIcon from "assets/images/icons/refresh.svg"
import _ from "lodash"

export default function ApartmentFilter({ className }) {
  const availableApartments = useAvailableApartments()
  const priceRange = useStore(state => state.priceRange)
  const setPriceRange = useStore(state => state.setPriceRange)
  const lwActiveFloor = useStore(state => state.lwActiveFloor)

  const selectedBedrooms = useStore(state => state.selectedBedrooms)
  const setSelectedBedrooms = useStore(state => state.setSelectedBedrooms)
  const selectedAspects = useStore(state => state.selectedAspects)
  const setSelectedAspects = useStore(state => state.setSelectedAspects)

  const activeApartment = useStore(state => state.activeApartment)

  const minPrice = useStore(state => state.minPrice)
  const maxPrice = useStore(state => state.maxPrice)

  const resetFilter = useStore(state => state.resetFilter)

  const [filterVisible, setFilterVisible] = useState(false)

  useEffect(() => {
    // lwActiveFloor is 99 when first landing on the apartment finder so all the lights should be on
    if (lwActiveFloor == 99) lightsOn(availableApartments)

    if (lwActiveFloor > 0 && lwActiveFloor < 99 && !activeApartment) {
      const filtered = apartmentsFilterFn
        .filter(availableApartments)
        .byFloor(lwActiveFloor)

      lightsOn(filtered.apartments)
    }

    if (activeApartment) {
      lightsOn([activeApartment])
    }
  }, [priceRange, activeApartment, lwActiveFloor, availableApartments])

  const handleVisiblity = () => {
    setFilterVisible(!filterVisible)
  }

  const handleReset = () => {
    resetFilter()
  }

  return (
    <Holder className={className}>
      <Shower>
        <ShowButton icon={FilterIcon} onClick={handleVisiblity} />
        <span>Filter</span>
      </Shower>
      <AnimatePresence>
        {filterVisible && (
          <FilterDrawer
            initial="initial"
            animate={filterVisible ? "animate" : "exit"}
            exit="exit"
            variants={{
              initial: {
                x: "-100%"
              },
              animate: {
                x: 0
              },
              exit: {
                x: "-100%"
              }
            }}
            transition={{ duration: 0.6 }}
          >
            <HiddenButton icon={FilterCloseIcon} onClick={handleVisiblity} />
            <ItemList>
              <ItemHolder>
                <ItemIcon src={PriceIcon} />
                <PriceSlider>
                  <p>{priceFormatter.format(priceRange[0])}</p>
                  <Slider
                    value={priceRange}
                    onChange={e => {
                      if (!_.isEqual(priceRange, e.value))
                        setPriceRange(e.value)
                      else return
                    }}
                    range
                    step={10000}
                    min={minPrice}
                    max={maxPrice}
                    style={{ width: "220px" }}
                  />
                  <p>{priceFormatter.format(priceRange[1])}</p>
                </PriceSlider>
              </ItemHolder>
              <ItemHolder>
                <ItemIcon src={BedIcon} />
                <List>
                  {BEDS.map((bed, index) => (
                    <ListItem
                      key={index}
                      onClick={() => {
                        const newVal = Array.from(selectedBedrooms)
                        newVal[index] = !selectedBedrooms[index]
                        setSelectedBedrooms(newVal)
                      }}
                      active={selectedBedrooms[index]}
                    >
                      {bed}
                    </ListItem>
                  ))}
                </List>
              </ItemHolder>
              <ItemHolder>
                <ItemIcon src={DirIcon} />
                <List>
                  {DIRECTIONS.map((dir, index) => (
                    <ListItem
                      key={index}
                      onClick={() => {
                        const newVal = Array.from(selectedAspects)
                        newVal[index] = !selectedAspects[index]
                        setSelectedAspects(newVal)
                      }}
                      active={selectedAspects[index]}
                    >
                      {dir}
                    </ListItem>
                  ))}
                </List>
              </ItemHolder>
            </ItemList>
            <RefreshButton icon={RefreshIcon} onClick={handleReset} />
          </FilterDrawer>
        )}
      </AnimatePresence>
    </Holder>
  )
}

const Holder = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
  padding: 0 0 1em 1em;
`

const Shower = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  span {
    font-family: Brother-1816-Book;
    color: #1e493b;
    font-size: 2em;
  }
`

const ShowButton = styled(IconButton)`
  width: 3.5em;
  height: 3.5em;
`

const FilterDrawer = styled(motion.div)`
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: 100vw;
  display: flex;
  flex-direction: row;
  background-color: #1d3d27;
  padding: 0.7em 5em 0.7em 1em;
  gap: 1em;
`

const HiddenButton = styled(IconButton)`
  min-width: 3.5em;
  min-height: 3.5em;
`

const RefreshButton = styled(IconButton)`
  min-width: 3.5em;
  min-height: 3.5em;
`

const ItemList = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1.5em;
`

const ItemHolder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1em;
`

const ItemIcon = styled.img`
  width: 2.5em;
  height: 2.5em;
`
const PriceSlider = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1em;

  p {
    font-family: LouvetteDeck-Regular;
    color: #e5e1e1;
    width: 4.5em;
  }
`

const List = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1em;
  margin-right: 1rem;
`
const ListItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url(${CircleIcon});
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  width: 2.5em;
  height: 2.5em;
  color: white;
  cursor: pointer;
  font-family: LouvetteDeck-Regular;
  background-color: ${props => (props.active ? "#919C3F" : "transparent")};
  border-radius: 50%;
  transition: background-color 0.3s ease-out;
`
