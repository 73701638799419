import styled from "styled-components"
import SwiperCore, { EffectCoverflow, Pagination } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react/swiper-react.js"
import "swiper/swiper.min.css"
import "swiper/modules/effect-coverflow/effect-coverflow.min.css"
import "swiper/modules/pagination/pagination.min.css"

import { useStore } from "state/store"

import PageTransitionHolder from "components/PageTransitionHolder"
import ApartmentCard from "components/ApartmentCard"
import ShareDialog from "components/ShareDialog"
import IconButton from "components/Buttons/IconButton"
import CompareDialog from "components/CompareDialog"
import PrintShare from "pages/Share/PrintShare"

import ShareIcon from "assets/images/icons/icon-share.svg"
import CompareIcon from "assets/images/icons/icon-compare.svg"
import MarkerIcon from "assets/images/icons/marker.svg"

import "./Favourites.css"

SwiperCore.use([EffectCoverflow, Pagination])

export default function Favourites() {
  const setOpenShareDialog = useStore(state => state.setOpenShareDialog)
  const setOpenCompareDialog = useStore(state => state.setOpenCompareDialog)
  const favourites = useStore(state => state.favourites)
  const setFavourites = useStore(state => state.setFavourites)

  const handleShareClick = () => {
    setOpenShareDialog(true)
  }

  const handleCompareClick = () => {
    setOpenCompareDialog(true)
  }

  const handleResetClick = () => {
    setFavourites([])
  }

  return (
    <PageTransitionHolder>
      <Holder>
        {favourites.length === 0 ? (
          <NoData>No favourites just yet</NoData>
        ) : (
          <>
            <Swiper
              className="fav-swiper"
              navigation={false}
              pagination={{
                clickable: true
              }}
              scrollbar={{ draggable: true, hide: true }}
              grabCursor={true}
              effect="coverflow"
              coverflowEffect={{
                rotate: 10,
                stretch: 0,
                depth: 20,
                modifier: 1,
                slideShadows: false
              }}
              slidesPerView={1.25}
              centeredSlides={true}
              spaceBetween={150}
            >
              {favourites.map((apartment, index) => (
                <SwiperSlide key={index}>
                  <ApartCardWrapper>
                    <ApartmentCard apartment={apartment} favVisible={true} />
                  </ApartCardWrapper>
                </SwiperSlide>
              ))}
            </Swiper>
            <Controls>
              <ResetButton icon={MarkerIcon} onClick={handleResetClick} />
              <ShareButton icon={ShareIcon} onClick={handleShareClick} />
              <CompareButton icon={CompareIcon} onClick={handleCompareClick} />
            </Controls>
            <ShareDialog />
            <CompareDialog />
            <PrintShare />
          </>
        )}
      </Holder>
    </PageTransitionHolder>
  )
}

const Holder = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #ddd0c6;
`

const ApartCardWrapper = styled.div`
  position: relative;
  height: 95%;
  max-height: 900px;
  width: 100%;
  max-width: 1300px;
`

const NoData = styled.div`
  font-family: DistinctStyleScript;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 2em;
  font-size: 7vw;
  color: #fff;
  text-align: center;
  line-height: 150%;
  letter-spacing: 0.1em;
  transform: rotate(-10deg);
`
const Controls = styled.div`
  position: absolute;
  top: 1em;
  right: 1em;
  z-index: 1;
  display: flex;
  flex-direction: column;
  gap: 0.9em;
`

const ShareButton = styled(IconButton)`
  width: 3.5em;
  height: 3.5em;
`

const CompareButton = styled(IconButton)`
  width: 3.5em;
  height: 3.5em;
`
const ResetButton = styled(IconButton)`
  width: 3.5em;
  height: 3.5em;
`
