import { useMeasure } from "react-use"
import styled from "styled-components"

import PageTransitionHolder from "components/PageTransitionHolder"

import CarouselIcon from "components/Icons/CarouselIcon"
import GridIcon from "components/Icons/GridIcon"
import { useStore } from "state/store"

import GalleryCarousel from "./Gallery.Carousel"
import GalleryGrid from "./Gallery.Grid"
import GalleryDialog from "./Gallery.Dialog"

export default function Gallery() {
  const isCarousel = useStore(state => state.isCarousel)
  const setIsCarousel = useStore(state => state.setIsCarousel)

  const [holderRef, holderMeasure] = useMeasure()

  const handleViewSwitch = () => {
    setIsCarousel(!isCarousel)
  }

  return (
    <PageTransitionHolder>
      <Holder ref={holderRef}>
        <ViewSwitcher onClick={handleViewSwitch}>
          {isCarousel ? <GridIcon /> : <CarouselIcon />}
        </ViewSwitcher>
        {isCarousel ? (
          <GalleryCarousel spaceSize={holderMeasure.width * 0.1} />
        ) : (
          <GalleryGrid
            spaceSize={holderMeasure.width * 0.03}
            itemHeight={holderMeasure.height * 0.38}
          />
        )}
      </Holder>
      <GalleryDialog />
    </PageTransitionHolder>
  )
}

const Holder = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: #cfbeae;
  color: white;
`

const ViewSwitcher = styled.button`
  position: absolute;
  top: 2em;
  right: 2em;
  z-index: 2;
  width: 3.5em;
  height: 3.5em;
  background-color: transparent;
  cursor: pointer;
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5em;
  border-radius: 50%;
  transition: all 0.3s ease-out;
  &:hover {
    box-shadow: 0 0 0.2em 0.2em rgba(0, 0, 0, 0.1);
  }
`
