import styled from "styled-components"

import { useStore } from "state/store"

import { GalleryData } from "./Gallery.data"

import "./Gallery.Grid.css"

// Core modules imports are same as usual
import SwiperCore, { Navigation, Pagination, Grid } from "swiper"
// Direct React component imports
import { Swiper, SwiperSlide } from "swiper/react/swiper-react.js"

// install Swiper modules
SwiperCore.use([Pagination, Navigation, Grid])

export default function GalleryGrid({ spaceSize = 100, itemHeight = 200 }) {
  const setOpenGalleryDialog = useStore(state => state.setOpenGalleryDialog)
  const setSelectedGallery = useStore(state => state.setSelectedGallery)

  const handleImageClick = (event, item) => {
    event.stopPropagation()
    setSelectedGallery(item)
    setOpenGalleryDialog(true)
  }

  return (
    <Swiper
      slidesPerView={3}
      spaceBetween={spaceSize}
      // centeredSlides={true}
      slidesPerGroup={6}
      pagination={{
        clickable: true
      }}
      grid={{ rows: 2, fill: "row" }}
      navigation={true}
      className="grid-swiper"
    >
      {GalleryData.map((item, index) => (
        <SwiperSlide key={index}>
          <SlideContent height={itemHeight}>
            <SlideImage
              img={`/assets/images/gallery/${item.thumb}`}
              onClick={event => handleImageClick(event, item)}
            />
          </SlideContent>
        </SwiperSlide>
      ))}
    </Swiper>
  )
}

const SlideContent = styled.div`
  width: 100%;
  height: ${props => props.height}px;
  position: relative;
`
const SlideImage = styled.div`
  width: 100%;
  height: 100%;
  cursor: pointer;
  position: relative;
  background-image: url(${props => props.img});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
`
