async function getApartments() {
  const apartments = await fetch(
    `${process.env.PUBLIC_URL}/apartments.json`
  ).then(res => res.json())

  return await apartments
}

export default {
  parseApartments: async apartments => {
    const apartmentsMap = await getApartments()

    if (apartments === "allOn") {
      const lightsOn = apartmentsMap
        .map(ap => ap.LightswarmDeviceAddress)
        .map(light => light.split("+"))
        .flat(1)
        .map(light => parseFloat(light))

      return { lightsOn, lightsOff: [] }
    }

    const filteredLightsOn = apartmentsMap.filter(ap1 =>
      apartments.some(ap2 => ap1?.Plot === ap2?.Plot)
    )

    const lightsOn = filteredLightsOn
      .map(ap => ap.LightswarmDeviceAddress)
      .map(light => light.split("+"))
      .flat(1)
      .map(light => parseFloat(light))

    const filteredLightsOff = apartmentsMap.filter(
      ap1 => !apartments.some(ap2 => ap1?.Plot === ap2?.Plot)
    )

    const lightsOff = filteredLightsOff
      .map(ap => ap.LightswarmDeviceAddress)
      .map(light => light.split("+"))
      .flat(1)
      .map(light => parseFloat(light))

    return { lightsOn, lightsOff: [], lightsDimmed: lightsOff }
  },

  lightUpFavourites: async (fav, dimmed) => {
    const apartmentsMap = await getApartments()

    const filteredLightsOn = await apartmentsMap.filter(
      ap1 =>
        fav.some(ap2 => ap1.Plot === ap2.Plot) &&
        !dimmed.some(ap2 => ap1.Plot === ap2.Plot)
    )

    const filteredLightsDimmed = await apartmentsMap.filter(ap1 =>
      dimmed.some(ap2 => ap1.Plot === ap2.Plot)
    )

    const filteredLightsOff = await apartmentsMap.filter(
      ap1 => !fav.some(ap2 => ap1.Plot === ap2.Plot)
    )

    // Parse unit object to Lightswarm address
    const parsedFavOn = await filteredLightsOn
      .map(ap => ap.LightswarmDeviceAddress)
      .map(light => light?.split("+"))
      .flat(1)
      .map(light => parseFloat(light))

    // Parse unit object to Lightswarm address
    const parsedFavDimmed = await filteredLightsDimmed
      .map(ap => ap.LightswarmDeviceAddress)
      .map(light => light?.split("+"))
      .flat(1)
      .map(light => parseFloat(light))

    const lightsOff = await filteredLightsOff
      .map(ap => ap.LightswarmDeviceAddress)
      .map(light => light.split("+"))
      .flat(1)
      .map(light => parseFloat(light))

    return { lightsOn: parsedFavOn, lightsOff, lightsDimmed: parsedFavDimmed }
  }
}
