import TWEEN from "@tweenjs/tween.js"

import { useRef, memo } from "react"
import { useThree, extend, useFrame } from "@react-three/fiber"
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls"

extend({ OrbitControls })

function CameraControls() {
  const controls = useRef()

  const {
    camera,
    gl: { domElement }
  } = useThree()

  useFrame(({ clock, camera }) => {
    TWEEN.update()

    controls.current.update()
  })

  return (
    <orbitControls
      enableZoom={true}
      maxDistance={10}
      minDistance={15}
      enablePan={false}
      // enabled={activeApartment ? false : true}
      enableDamping={true}
      maxPolarAngle={1.2}
      minPolarAngle={1.2}
      // maxPolarAngle={Math.PI / 2 - 0.35}
      ref={controls}
      args={[camera, domElement]}
    />
  )
}

export default memo(CameraControls)
