function returnPlotNumber(name) {
  return `${name.split("-")[1]}.${name.split("-")[2]}`
}

export const helpers = {
  returnPlotNumber: returnPlotNumber
}

export const meshNameToPlotId = name => {
  return name.split("-")[1] + "." + name.split("-")[2]
}
