import styled from "styled-components"
import { AnimatePresence, motion } from "framer-motion"

import HighlightsImage from "assets/images/location/highlights.svg"

export default function LocationHighlights() {
  return (
    <AnimatePresence>
      <Holder
        initial="initial"
        animate="animate"
        exit="hidden"
        variants={{
          initial: {
            y: 40,
            opacity: 0
          },
          animate: {
            y: 0,
            opacity: 1
          },
          exit: {
            y: 40,
            opacity: 1
          }
        }}
        transition={{ duration: 0.6 }}
      ></Holder>
    </AnimatePresence>
  )
}

const Holder = styled(motion.div)`
  width: 100%;
  height: 100%;
  background-image: url(${HighlightsImage});
  background-size: auto 140%;
  background-position: center;
  background-repeat: no-repeat;
`
