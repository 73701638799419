import styled from "styled-components"
import { motion } from "framer-motion"
import { useStore } from "state/store"
import IconButton from "components/Buttons/IconButton"

import BackIcon from "assets/images/icons/marker.svg"
import OverlayImage from "assets/images/location/travel-time-overlay.svg"

export default function TravelTime({ visible }) {
  const setOpenTimeLocation = useStore(state => state.setOpenTimeLocation)
  return (
    <Holder
      initial="hidden"
      animate={visible ? "visible" : "hidden"}
      exit="hidden"
      variants={{
        visible: {
          x: 0,
          transition: {
            ease: [0.0, 0.0, 0.21, 0.89],
            duration: 0.6
          }
        },
        hidden: {
          x: 1000,
          transition: {
            ease: [0.0, 0.0, 0.21, 0.89],
            duration: 0.6
          }
        }
      }}
    >
      <BackButton
        icon={BackIcon}
        onClick={() => {
          setOpenTimeLocation(false)
        }}
      />
      <Content>
        <img height="99%" src={OverlayImage} alt="Travel time overlay" />
      </Content>
    </Holder>
  )
}

// const Holder = styled.div`
const Holder = styled(motion.div)`
  position: fixed;
  right: 0;
  top: 0;
  z-index: 9999;
  width: 100%;
  max-width: 900px;
  height: 100vh;
  background-color: #d1b594;
  overflow: hidden;
`
const Content = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  img {
    width: 100%;
  }
`

const BackButton = styled(IconButton)`
  position: absolute;
  top: 0.5em;
  right: 0.5em;
  z-index: 1;
  width: 3em;
`
