import React, { useState } from "react"
import styled from "styled-components"
import ReactPlayer from "react-player"
import { AnimatePresence, motion } from "framer-motion"

import PageTransitionHolder from "components/PageTransitionHolder"
import PrevButton from "components/Buttons/PrevButton"

import Slide1Image from "assets/images/film/slide1.jpg"
import Slide1TitleImage from "assets/images/film/slide1_title.png"
import Slide1Video from "assets/videos/life_at_willow_walk.mp4"
import Slide2Image from "assets/images/film/slide2.jpg"
import Slide2TitleImage from "assets/images/film/slide2_title.png"
import Slide2Video from "assets/videos/film_2.mp4"
import PlayOverlayImage from "assets/images/film/play_overlay.svg"

import "./Film.css"

// Core modules imports are same as usual
import SwiperCore, { Navigation, Pagination } from "swiper"
// Direct React component imports
import { Swiper, SwiperSlide } from "swiper/react/swiper-react.js"

// install Swiper modules
SwiperCore.use([Pagination, Navigation])

export default function Film() {
  const [showVideo, setShowVideo] = useState(false)
  const [currentVideo, setCurrentVideo] = useState(Slide1Video)

  const handleSlideClick = index => {
    setShowVideo(true)
    if (index === 0) {
      setCurrentVideo(Slide1Video)
    } else if (index === 1) {
      setCurrentVideo(Slide2Video)
    }
  }

  const handleVideoClick = e => {
    e.stopPropagation()
    setShowVideo(false)
  }

  return (
    <PageTransitionHolder>
      <Holder>
        <StyledSwiper
          // pagination={{
          //   type: "progressbar"
          // }}
          navigation={true}
        >
          <SwiperSlide>
            <SlideContent>
              <SlideImage
                img={Slide1Image}
                onClick={() => {
                  handleSlideClick(0)
                }}
              />
              <SlideTitle src={Slide1TitleImage} />
            </SlideContent>
          </SwiperSlide>
          <SwiperSlide>
            <SlideContent>
              <SlideImage
                img={Slide2Image}
                onClick={() => {
                  handleSlideClick(1)
                }}
              />
              <SlideTitle src={Slide2TitleImage} />
            </SlideContent>
          </SwiperSlide>
        </StyledSwiper>
        <AnimatePresence>
          {showVideo && (
            <PlayerHolder {...AnimationSettings} onClick={handleVideoClick}>
              <StyledPrevButton onClick={handleVideoClick} />
              <ReactPlayer
                url={currentVideo}
                width="100%"
                height="100%"
                playing={true}
                controls={true}
                config={{
                  file: { attributes: { poster: "noposter" } }
                }}
              />
            </PlayerHolder>
          )}
        </AnimatePresence>
      </Holder>
    </PageTransitionHolder>
  )
}

const Holder = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: #1d3d27;
  color: white;
`
const StyledSwiper = styled(Swiper)`
  width: 100%;
  height: 100%;
`
const SlideContent = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 90vw;
  height: 40vw;
  position: relative;
`
const SlideImage = styled.div`
  width: 100%;
  height: 100%;
  margin: 0;
  margin-left: 20vw;
  cursor: pointer;
  position: relative;
  background-image: url(${props => props.img});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url(${PlayOverlayImage});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    opacity: 0;
    transition: opacity 0.3s ease-in;
  }
  &:hover {
    &:before {
      opacity: 1;
    }
  }
`
const SlideTitle = styled.img`
  position: absolute;
  left: 0;
  bottom: -3vw;
  z-index: 1;
  height: 10vw;
  pointer-events: none;
`

const PlayerHolder = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  padding: 5em 1em;
  background-color: black;
`

const StyledPrevButton = styled(PrevButton)`
  position: absolute;
  bottom: 3em;
  left: 2em;
  z-index: 1;
`

const AnimationSettings = {
  transition: { duration: 0.5 },
  initial: { opacity: 0, scale: 0.2 },
  animate: { opacity: 1, scale: 1 },
  exit: { opacity: 0, scale: 1 }
}
