import styled from "styled-components"
import { motion } from "framer-motion"
import { Link } from "react-router-dom"
import { device } from "theme/device"
import DelayLink from "utils/components/DelayLink"
import CloseIcon from "assets/images/icons/markerwhite.svg"

const variants = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 }
    }
  },
  closed: {
    y: 50,
    opacity: 0,
    tramenuVisiblesition: {
      y: { stiffness: 1000 }
    }
  }
}

export const MenuItem = ({ label, path, toggle }) => {
  return (
    <>
      {path.length === 1 ? (
        <Home variants={variants} onClick={toggle}>
          <Link to={"/"} style={{ width: "100%", height: "100%" }}>
            <img
              onClick={() => {
                setTimeout(() => {
                  window.location.reload()
                }, 1000)
              }}
              src={CloseIcon}
              alt="back"
            />
          </Link>
        </Home>
      ) : (
        <Item
          onClick={toggle}
          variants={variants}
          whileHover={{ scale: 1 }}
          whileTap={{ scale: 0.95 }}
        >
          <Text
            to={path}
            delay={1000}
            onDelayStart={() => {}}
            onDelayEnd={() => {}}
          >
            {label}
          </Text>
        </Item>
      )}
    </>
  )
}

const Home = styled(motion.div)`
  position: absolute;
  top: 1.5em;
  right: 1.5em;
  width: 3.5rem;
  height: 3.5rem;
`

const Item = styled(motion.li)`
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  cursor: pointer;
`

const Text = styled(DelayLink)`
  font-family: LouvetteDeck-Regular;
  font-size: 2.5vw;
  color: #e2e7e2;
  border-radius: 5px;
  transition: all 0.3s ease-out;
  &:hover {
    opacity: 0.5;
  }
  @media ${device.pad} {
    font-size: 10vw;
  }
`
