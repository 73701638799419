import { useState, useEffect } from "react"
import styled from "styled-components"
import PageTransitionHolder from "components/PageTransitionHolder"
import TargetIcon from "assets/images/home/target.svg"
import { device } from "theme/device"

import ReactPlayer from "react-player"
import OrbitVideo from "assets/videos/orbit.mp4"
import { useHistory } from "react-router-dom"

export default function Home() {
  const [showEnter, setShowEnter] = useState(false)

  const history = useHistory()

  useEffect(() => {
    setTimeout(() => {
      setShowEnter(true)
    }, 3500)
  }, [])

  return (
    <>
      <PageTransitionHolder>
        <Holder>
          <OverViewHolder>
            <div
              onClick={() => history.push("/orbits")}
              style={{
                cursor: "pointer",
                pointerEvents: "auto",
                left: "63%",
                top: "70%",
                zIndex: 1,
                position: "absolute",
                height: "50px",
                width: "50px"
              }}
            >
              {showEnter && <Hotspot visible={true} />}
            </div>

            <ReactPlayer
              style={{ pointerEvents: "none" }}
              loop={false}
              url={OrbitVideo}
              width="100%"
              height="100%"
              playing={true}
              muted={true}
              playsinline={false}
              onStart={() => {
                setShowEnter(false)
              }}
              onEnded={() => {
                setShowEnter(true)
              }}
              config={{
                file: { attributes: { poster: "noposter" } }
              }}
            />
          </OverViewHolder>
        </Holder>
      </PageTransitionHolder>
    </>
  )
}

const Hotspot = styled.button`
  position: absolute;
  top: ${props => props.y}px;
  left: ${props => props.x}px;
  z-index: 1;
  /* z-index: 1; */
  width: 3.5em;
  height: 3.5em;
  border-radius: 50%;
  border: none;
  outline: none;
  background-color: transparent;
  background-image: url(${TargetIcon});
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
  opacity: ${props => (props.visible ? 1 : 0)};
  transition: opacity 0.3s ease-in-out;
  animation: pulse 2s infinite;
  @keyframes pulse {
    0% {
      box-shadow: 0 0 0 0 rgb(255 255 0 / 70%);
    }
    70% {
      box-shadow: 0 0 0 25px rgb(255 255 0 / 0%);
    }
    100% {
      box-shadow: 0 0 0 0 rgb(255 255 0 / 0%);
    }
  }
  &:hover {
    opacity: 0.6;
  }
  @media ${device.mobileL} {
    width: 2em;
    height: 2em;
  }
`

const Holder = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  video {
    object-fit: cover;
  }
`

const OverViewHolder = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  padding: 0;
  background: #687e3e;
`
