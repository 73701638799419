import { useRef, useState } from "react"
import styled from "styled-components"
import { motion, useCycle } from "framer-motion"
import { useDimensions } from "./use-dimensions"
import { MenuToggle } from "./MenuToggle"
import { Navigation } from "./Navigation"
import { device } from "theme/device"

import AnimatedPathContainer from "components/AnimatedPaths/AnimatedPathContainer"
import { pathData } from "./data"

const sidebar = {
  open: (height = 1000) => ({
    clipPath: `circle(${height * 2 + 800}px at 3em 3em)`,
    transition: {
      type: "spring",
      stiffness: 20,
      restDelta: 2
    }
  }),
  closed: {
    clipPath: "circle(1px at 3em 3em)",
    transition: {
      delay: 0.5,
      type: "spring",
      stiffness: 400,
      damping: 40
    }
  }
}

export default function SideMenu() {
  const [isOpen, toggleOpen] = useCycle(false, true)
  const containerRef = useRef(null)
  const { height } = useDimensions(containerRef)
  const [index, setIndex] = useState(0)

  return (
    <>
      <Menu
        initial={false}
        animate={isOpen ? "open" : "closed"}
        custom={height}
        ref={containerRef}
        active={isOpen}
      >
        <AnimatedPathContainer
          index={index}
          setIndex={setIndex}
          data={pathData[index]}
          isOpen={isOpen}
        />
        <Background variants={sidebar} />
        <Navigation toggle={() => toggleOpen()} />
      </Menu>
      <MenuToggle open={isOpen} toggle={() => toggleOpen()} />
    </>
  )
}

const Menu = styled(motion.nav)`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 106;
  width: 100%;
  height: 100%;
  pointer-events: ${({ active }) => (active ? "auto" : "none")};
  @media ${device.mobileL} {
    width: 100%;
  }
`
const Background = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #1f3d29;
  @media ${device.mobileL} {
    width: 100%;
  }
`
