import {
  useRef,
  useEffect,
  // createRef,
  useState,
  useLayoutEffect,
  useCallback
} from "react"
import { Math } from "three"
import styled from "styled-components"

import BuildingScene from "./Scenes/BuildingScene"
import { FloorsScene } from "./Scenes/FloorsScene"
import FloorButtons from "./UI/FloorButtons"
import CompassDials from "assets/images/finder/case.svg"
import CompassCase from "assets/images/finder/dials.svg"
import Key from "assets/images/finder/key.svg"
import BookmarkIcon from "assets/images/icons/icon-bookmark.svg"
import IconButton from "components/Buttons/IconButton"

import { useHistory } from "react-router-dom"

// https://codesandbox.io/s/react-three-fiber-scroll-rig-sbh7v?file=/src/App.js:1069-1077
// https://codesandbox.io/s/react-three-fiber-with-scroll-event-63uls?file=/src/Mesh.js

export default function BuildingExplorer({ baseState }) {
  const history = useHistory()
  const scrollAreaRef = useRef()
  const containerRef = useRef()
  const scrollContentRef = useRef()
  const compassRef = useRef()

  const [scrollState, setScrollState] = useState(0)
  const [levels, setLevels] = useState([])

  useEffect(() => {
    scrollAreaRef.current.scrollTop = scrollState
  }, [scrollState])

  //  Scroll Events
  const onScroll = e => {
    baseState.top.current = e.target.scrollTop
  }
  const handleClick = v => {
    setScrollState(v)
    baseState.top.current = scrollState
  }

  const handleBookmark = () => {
    history.push("/favourites")
  }

  const compassRotationCallback = useCallback(v => {
    compassRef.current.style.transform = `rotate(${
      Math.radToDeg(v.theta) + 18
    }deg)`
  }, [])

  // Calculate and set tofloor level values based on scroll div height;
  useLayoutEffect(() => {
    const testArr = []
    let start = 0
    // const interval =
    //   scrollContentRef.current.getBoundingClientRect().height /
    //   baseState.sections

    const interval = window.innerHeight

    for (let i = 0; i < baseState.sections; i++) {
      testArr.push(start)
      start += interval
    }

    setLevels(testArr)
  }, [baseState.sections])

  return (
    <Holder>
      <BookmarkButton icon={BookmarkIcon} onClick={handleBookmark} />
      <FloorButtons levels={levels} handleClick={handleClick} />
      <KeyHolder src={Key} alt="key" />
      <CompassWrapper>
        <CompassImgDials
          width="100%"
          height="100%"
          ref={compassRef}
          src={CompassDials}
        />
        <CompassImgCase ref={compassRef} src={CompassCase} />
      </CompassWrapper>
      <BuildingContainer>
        <BuildingScene compassRotationCallback={compassRotationCallback} />
      </BuildingContainer>
      <FloorPlanContainer ref={containerRef}>
        <FloorsScene scrollArea={scrollAreaRef} baseState={baseState} />
        <ScrollArea ref={scrollAreaRef} onScroll={onScroll}>
          <ScrollContent
            ref={scrollContentRef}
            height={baseState.pages * window.innerHeight}
          />
        </ScrollArea>
      </FloorPlanContainer>
    </Holder>
  )
}

const Holder = styled.div`
  position: absolute;
  display: flex;
  flex-direction: row;
  background-color: #e1e1e1;
  width: 100%;
  height: 100%;
`

const BuildingContainer = styled.div`
  position: relative;
  width: 50%;
  height: 100%;
`

const FloorPlanContainer = styled.div`
  position: relative;
  width: 50%;
  height: 100%;
  z-index: 0;
`
const ScrollArea = styled.div`
  box-sizing: border-box;
  /* border: green solid 1px; */
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    /* display: none; */
  }
`
const KeyHolder = styled.img`
  position: absolute;
  top: 0;
  left: 50%;
  height: 2em;
  margin: 1rem;
  transform: translateX(-50%);
`

const ScrollContent = styled.div`
  box-sizing: border-box;
  /* border: hotpink solid 4px; */
  pointer-events: none;
  user-select: none;
  height: ${props => props.height}px;
`

const CompassWrapper = styled.div`
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 3;
  width: 4.5em;
  height: 4.5em;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
`

const CompassImgDials = styled.img`
  position: absolute;
  width: 170%;
  transform: rotate(${props => props.rotation}deg);
`
const CompassImgCase = styled.img`
  width: 150%;
  transform: rotate(${props => props.rotation}deg);
`

const BookmarkButton = styled(IconButton)`
  position: absolute;
  top: 0;
  right: 2em;
  width: 3.5em;
  z-index: 3;
`
