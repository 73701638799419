import styled from "styled-components"

import { useStore } from "state/store"

import { GalleryData } from "./Gallery.data"

import "./Gallery.Carousel.css"

// Core modules imports are same as usual
import SwiperCore, { Navigation, Pagination, Scrollbar } from "swiper"
// Direct React component imports
import { Swiper, SwiperSlide } from "swiper/react/swiper-react.js"

// install Swiper modules
SwiperCore.use([Pagination, Navigation, Scrollbar])

export default function GalleryCarousel({ spaceSize = 100 }) {
  const setOpenGalleryDialog = useStore(state => state.setOpenGalleryDialog)
  const setSelectedGallery = useStore(state => state.setSelectedGallery)

  const handleImageClick = (event, item) => {
    event.stopPropagation()
    setSelectedGallery(item)
    setOpenGalleryDialog(true)
  }

  return (
    <Swiper
      slidesPerView={1.5}
      spaceBetween={spaceSize}
      centeredSlides={true}
      // pagination={{
      //   clickable: true
      // }}
      navigation={true}
      scrollbar={{
        hide: false,
        draggable: true
      }}
      className="carousel-swiper"
    >
      {GalleryData.map((item, index) => (
        <SwiperSlide key={index}>
          <SlideContent>
            <SlideImage
              img={`/assets/images/gallery/${item.thumb}`}
              onClick={event => handleImageClick(event, item)}
            />
            <SlideTitle>{item.title}</SlideTitle>
          </SlideContent>
        </SwiperSlide>
      ))}
    </Swiper>
  )
}

const SlideContent = styled.div`
  width: 75vw;
  height: 45vw;
  max-height: 70vh;
  position: relative;
`
const SlideImage = styled.div`
  width: 100%;
  height: 100%;
  cursor: pointer;
  position: relative;
  background-image: url(${props => props.img});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
`
const SlideTitle = styled.p`
  position: absolute;
  left: -5vw;
  bottom: 1.5vw;
  z-index: 1;
  pointer-events: none;
  font-family: DistinctStyleScript;
  text-transform: capitalize;
  font-size: 4vw;
  line-height: 100%;
  color: #fef7ee;
  margin: 0;
  padding: 0;
`
