import { lazy, Suspense } from "react"
import { Canvas, useFrame } from "@react-three/fiber"
import * as THREE from "three"

import { useStore } from "state/store"
import CameraControls from "components/BuildingExplorer/Utils/CameraControls"
import Building from "../Building/BuildingLoader"
import { DoubleSide, MathUtils } from "three"
import { Text } from "@react-three/drei"
import { range } from "lodash-es"

const cFont = `${process.env.PUBLIC_URL}/fonts/LouvetteDeck-Regular.ttf`

export default function BuildingScene({ compassRotationCallback }) {
  return (
    <Canvas
      gl={{
        antialias: true
      }}
      dpr={1.5}
      camera={{
        fov: 35,
        near: 0.1,
        far: 1000,
        position: [20, 8, 30]
      }}
      colorManagement={false}
    >
      <spotLight position={[10, 14, 30]} penumbra={1} castShadow />
      <spotLight
        position={[-10, 14, -10]}
        penumbra={1}
        castShadow
        color="#D2A379"
        intensity={3}
      />
      <CompassRotation compassRotationCallback={compassRotationCallback} />
      <ambientLight />
      <Suspense fallback={"Loading"}>
        <StaticCompass />
        <Building />
      </Suspense>
      <CameraControls />
    </Canvas>
  )
}

export function StaticCompass() {
  const angles = range(0, 360, 5.625)
  // const angles = range(0, 360, 5.625)
  const markerAngles = range(0, 360, 90)

  const offset = 2.3

  // const angles = [-Math.PI / 2, 0, Math.PI / 2, Math.PI]
  const markers = ["N", "W", "S", "E"]

  const radius = 90
  // const radius = 140
  const thickness = 3
  const fontSize = 16
  return (
    <>
      <group rotation-y={-1.3} scale={0.035} position={[0, -2.3, 0]}>
        {angles.map(a => (
          <group key={a} rotation-y={MathUtils.degToRad(a) + offset}>
            <mesh
              position={[
                a % 45 === 0
                  ? //  && a % 90 !== 0
                    radius + thickness
                  : radius,
                0,
                0
              ]}
              rotation-x={Math.PI / 2}
            >
              <planeBufferGeometry
                args={[
                  a % 45 === 0
                    ? // && a % 90 !== 0
                      thickness * 2
                    : thickness,
                  1.2,
                  10,
                  10
                ]}
              />
              <meshBasicMaterial
                colorManagement={false}
                color="#0C1F15"
                transparent={true}
                opacity={
                  a % 45 === 0
                    ? 0.5 //  && a % 90 !== 0
                    : 0.2
                }
                side={DoubleSide}
              />
            </mesh>
          </group>
        ))}
        {markerAngles.map((a, i) => (
          <group
            key={a}
            //
            rotation-y={MathUtils.degToRad(a) + offset}
            //
          >
            <group
              position={[radius + thickness + fontSize - 3, 0, 0]}
              rotation-x={-Math.PI / 2}
              rotation-z={Math.PI / 2}
              //
            >
              <Text
                font={cFont}
                characters="abcdefghijklmnopqrstuvwxyz0123456789!"
                color="#060F0A"
                fontSize={fontSize}
                anchorX="center"
                anchorY="middle"
              >
                {markers[i]}
              </Text>
            </group>
          </group>
        ))}
      </group>
    </>
  )
}

//sunset, dawn, night, warehouse, forest, apartment, studio, city, park, lobby

function CompassRotation({ compassRotationCallback }) {
  const setApartRotation = useStore(state => state.setApartRotation)
  var dir = new THREE.Vector3()
  var sph = new THREE.Spherical()
  useFrame(({ camera }) => {
    camera.getWorldDirection(dir)
    setApartRotation(sph.setFromVector3(dir))
    compassRotationCallback(sph.setFromVector3(dir))
  })

  return null
}
