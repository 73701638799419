import { useState, useEffect } from "react"
import styled from "styled-components"
import { motion } from "framer-motion"
import { device } from "theme/device"
import { useHistory } from "react-router-dom"

export const MenuToggle = ({ open, toggle }) => {
  const history = useHistory()

  const [show, set] = useState(
    history.location.pathname === "/" ||
      history.location.search.includes("units")
      ? false
      : true
  )

  useEffect(() => {
    history.listen(location => {
      location.pathname === "/" || history.location.search.includes("units")
        ? set(false)
        : set(true)
    })
  }, [history])

  return (
    <ToggleButton style={{ display: show ? "" : "none" }} onClick={toggle}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="100%"
        viewBox="0 0 59.04 51.15"
      >
        <g id="Layer_2" data-name="Layer 2">
          <g id="Apartment-icons">
            <motion.path
              d="M58.79,25.58c.36,4,1,10.86-3.4,17-7.23,10.07-21.27,8.57-22,8.48-7.72-1-12.68-5.34-15.26-7.63-3.31-2.93-9.9-8.77-10.17-17.81C7.68,18,12.09,12.49,13.85,10.32,15.1,8.77,21.92.35,33.35.15A24.87,24.87,0,0,1,48.9,5.46C57.57,12.25,58.5,22.44,58.79,25.58Z"
              fill="#596f40"
              variants={{
                closed: { fill: "#596f40" },
                open: { fill: "#81b96b" }
              }}
              animate={open ? "open" : "closed"}
              transition={{ duration: 0.5 }}
            />
            <motion.path
              d="M38.25,19.33c.27,3,.73,8.08-2.52,12.61-5.38,7.48-15.82,6.37-16.39,6.3A20.51,20.51,0,0,1,8,32.57C5.54,30.39.64,26.05.43,19.33.26,13.66,3.54,9.6,4.84,8c.93-1.15,6-7.41,14.5-7.56A18.53,18.53,0,0,1,30.9,4.37C37.34,9.42,38,17,38.25,19.33Z"
              fill="none"
              strokeMiterlimit="10"
              strokeWidth={0.85}
              stroke="#1e493b"
              variants={{
                closed: {
                  strokeWidth: 0.85,
                  stroke: "#1e493b"
                },
                open: { strokeWidth: "1", stroke: "#ffffff" }
              }}
              animate={open ? "open" : "closed"}
              transition={{ duration: 0.5 }}
            />
          </g>
        </g>
      </svg>
    </ToggleButton>
  )
}

const ToggleButton = styled.button`
  outline: none;
  border: none;
  cursor: pointer;
  position: absolute;
  top: 1em;
  left: 1em;
  z-index: 106;
  width: 4.5em;
  height: 4.5em;
  border-radius: 50%;
  background: transparent;
  @media ${device.mobileL} {
    width: 3.5em;
    height: 3.5em;
  }
`
