import { MAPPED_PROPS } from "api/typeMappers"

export class ApartmentsFilter {
  static apartments = []

  filter(items) {
    this.apartments = items
    return this
  }

  byFloor(value) {
    this.apartments = this.apartments.filter(
      apt => parseInt(apt[MAPPED_PROPS.FLOOR]) === value
    )

    return this
  }

  byPriceRange(priceRange) {
    this.apartments = this.apartments.filter(
      apt =>
        apt[MAPPED_PROPS.PRICE] >= priceRange[0] &&
        apt[MAPPED_PROPS.PRICE] <= priceRange[1]
    )

    return this
  }

  byNumberOfBeds(numberOfBeds) {
    this.apartments = this.apartments.filter(
      apt => apt[MAPPED_PROPS.BEDS] === numberOfBeds
    )
  }

  byAspects(aspects) {
    this.apartments = this.apartments.filter(
      apt =>
        apt[MAPPED_PROPS.ASPECT].includes(aspects[0] ? "North" : "-") ||
        apt[MAPPED_PROPS.ASPECT].includes(aspects[1] ? "East" : "-") ||
        apt[MAPPED_PROPS.ASPECT].includes(aspects[2] ? "South" : "-") ||
        apt[MAPPED_PROPS.ASPECT].includes(aspects[3] ? "West" : "-")
    )

    return this
  }
}

export default {
  /**
   * Filtering function that returns itself so you chain the methog you want to filter by. E.g. filter(apartments).byFloor(2)
   * @param {array} apartments
   * @returns itself (this) so you can chain the method you want to filter by.
   */
  filter: apartments => {
    return new ApartmentsFilter().filter(apartments)
  },
  /**
   * @param {string|number} value
   * @returns an array of filtered apartments by floor.
   */
  byFloor: value => {
    return new ApartmentsFilter().byFloor(value)
  },
  /**
   * @param {array} floorRange
   * @returns an array of filtered apartments by floor range.
   */
  byFloorRange: floorRange => {
    return new ApartmentsFilter().byFloorRange(floorRange)
  },
  /**
   * @param {array} priceRange
   * @returns an array of filtered apartments by price range.
   */
  byPriceRange: priceRange => {
    return new ApartmentsFilter().byPriceRange(priceRange)
  },
  /**
   * @param {number} numberOfBeds
   * @returns an array of filtered apartments by number of beds.
   */
  byNumberOfBeds: numberOfBeds => {
    return new ApartmentsFilter().byNumberOfBeds(numberOfBeds)
  },
  /**
   * @param {array} aspects
   * @returns an array of filtered apartments by an array of aspects.
   */
  byAspects: aspects => {
    return new ApartmentsFilter().byAspects(aspects)
  }
}
