import styled from "styled-components"
import { device } from "theme/device"
import ApartmentCard from "components/ApartmentCard"
import WillowWalksIcon from "assets/images/share/willow_walks.svg"
import BrandIcon from "assets/images/share/brand.svg"
import { formatPlotName, useApartmentsByTypes } from "utils/hooks"
import { useStore } from "state/store"

export default function Share({ location }) {
  const setIsShare = useStore(state => state.setIsShare)

  const decoded = atob(location.search.split("?")[1])
  const plots = decoded.split(",")
  const { apartmentsByPlot } = useApartmentsByTypes()

  setIsShare(true)

  return (
    <Holder>
      {plots.length > 0 && (
        <MiddleContainer>
          {plots.length > 1 && (
            <Header>
              <h1>your favourites</h1>
              <img src={WillowWalksIcon} alt="" />
            </Header>
          )}
          <ApartCardList>
            {plots.map((plot, index) => (
              <>
                <ApartCard
                  key={index}
                  apartment={apartmentsByPlot[formatPlotName(plot)]}
                  isShare={true}
                />
                {index !== plots.length - 1 && <Divider />}
              </>
            ))}
          </ApartCardList>
          <Footer />
        </MiddleContainer>
      )}
    </Holder>
  )
}

const Holder = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: #ababab;
  color: white;
  font-family: Brother-1816-Book;
`

const MiddleContainer = styled.div`
  height: 100%;
  min-width: 300px;
  width: 80%;
  max-width: 1200px;
  margin: 0 auto;
  background-color: white;
  overflow: hidden;
  overflow-y: auto;
  @media ${device.pad} {
    width: 100%;
  }
`

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #1e493b;
  h1 {
    flex: 1;
    color: #e1d7c9;
    text-transform: capitalize;
    font-family: LouvetteDeck-Regular;
    font-size: 3.7em;
    letter-spacing: 0.1em;
    margin: 0 1em 0 1em;
    padding: 0;
    @media ${device.pad} {
      font-size: 5vw;
    }
  }
  img {
    width: 30%;
    max-width: 300px;
  }
`

const ApartCardList = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`

const ApartCard = styled(ApartmentCard)`
  width: 100%;
`

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #2c4f4366;
  margin: 1em 5vw;
`

const FooterHolder = styled.div`
  width: 100%;
  background-color: #efefef;
  padding: 2rem;
  font-family: LouvetteDeck-Regular;
  color: #1e493b;
  p {
    font-size: 2em;
    line-height: 150%;
    letter-spacing: 0.1em;
    margin: 0;
    padding: 0;
    text-transform: uppercase;
  }
  div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 0.5em;
    gap: 2em;
    font-family: Brother-1816-Book;
    div {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      gap: 0.5em;
      p {
        font-size: 0.9rem;
        line-height: 100%;
        margin: 0;
        padding: 0;
      }
      img {
        width: 15rem;
      }
      a {
        color: #1e493b;
      }
    }
  }
`

export const FooterContent = () => {
  return (
    <>
      <p>enquiries</p>
      <div>
        <div>
          <p>Willow Walk Marketing Suite</p>
          <p>London</p>
          <p>United Kingdom</p>
        </div>
        <div>
          <a
            href="mailto:willowwalk@peabody.org.uk"
            target="_blank"
            rel="noopenr noreferrer"
          >
            willowwalk@peabody.org.uk
          </a>
          <a
            href="https://www.peabodysales.co.uk/developments/willow-walk/"
            target="_blank"
            rel="noopenr noreferrer"
          >
            willow-walk.co.uk
          </a>
        </div>
      </div>
    </>
  )
}

const Footer = () => {
  return (
    <FooterHolder>
      <FooterContent />
    </FooterHolder>
  )
}
