export const MAPPED_PROPS = {
  ASPECT: "Aspect",
  PLOT: "Plot",
  TYPE: "Flat Type",
  BEDS: "Size (Bed/Person)",
  FLOOR: "Level",
  STATUS: "Availability",
  PRICE: "Share Price",
  FULL_VALUE: "Full Value",
  DEPOSIT_REQUIRED: "Deposit Required",
  SHARE_VALUE: "Share Value",
  MONTHLY_COST: "Monthly Cost",
  MONTHLY_MORTGAGE: "Monthly Mortgage",
  MONTHLY_RENT: "Monthly Rent",
  MONTHL_YCHARGE: "Monthly Service Charge",
  MIN_HOUSEHOLD_INCOME: "Minimum Household Income",
  MAX_HOUSEHOLD_INCOME: "Maximum Household Income"
}
