import { useStore } from "state/store"
import { useEffect, useRef } from "react"

export function Box({ name, className, children }) {
  const setFirstDustbin = useStore(state => state.setFirstDustbin)
  const setSecondDustbin = useStore(state => state.setSecondDustbin)

  const drag = useRef()
  const timer = useRef()

  useEffect(() => {
    drag.current?.addEventListener("click", event => {
      if (event.detail === 1) {
        timer.current = setTimeout(() => {
          setFirstDustbin(name)
        }, 200)
      }
    })
    drag.current?.addEventListener("dblclick", event => {
      clearTimeout(timer.current)
      setSecondDustbin(name)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <div ref={drag}>{children}</div>
}
