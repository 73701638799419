import { useState } from "react"
import { AnimatePresence, motion } from "framer-motion"
import styled from "styled-components"
import PageTransitionHolder from "components/PageTransitionHolder"
import PlanImage from "assets/images/showhome/plan.svg"
import NextButton from "components/Buttons/NextButton"
import useCheckInternetConnection from "utils/useCheckInternetConnection"

export default function ShowHome() {
  const [showPlan, setShowPlan] = useState(true)
  const { isConnected } = useCheckInternetConnection()

  const handleNextClick = e => {
    e.stopPropagation()
    setShowPlan(false)
  }

  return (
    <PageTransitionHolder>
      <AnimatePresence>
        {showPlan && (
          <PlanHolder {...AnimationSettings}>
            <Plan onClick={handleNextClick}>
              <StyledNextButton />
            </Plan>
          </PlanHolder>
        )}
      </AnimatePresence>
      <Holder>
        {isConnected !== null ? (
          isConnected === "online" ? (
            <iframe
              title="tour"
              width="100%"
              height="100%"
              frameBorder="0"
              allowFullScreen
              allow="xr-spatial-tracking; gyroscope; accelerometer"
              scrolling="no"
              src="https://biganto.com/tour/32297"
            ></iframe>
          ) : (
            <OfflineMessage>
              Sorry, this feature is not available offline.
            </OfflineMessage>
          )
        ) : (
          ""
        )}
      </Holder>
    </PageTransitionHolder>
  )
}

const OfflineMessage = styled.div`
  font-size: 3em;
`

const Holder = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: #1d3d27;
  color: white;
`
const PlanHolder = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: #222222;
`

const Plan = styled.div`
  position: relative;
  width: 90vw;
  max-width: 1200px;
  height: 40vw;
  max-height: 500px;
  background-image: url(${PlanImage});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
`

const StyledNextButton = styled(NextButton)`
  position: absolute;
  bottom: 1em;
  right: 2em;
  z-index: 1;
`

const AnimationSettings = {
  transition: { duration: 0.5 },
  initial: { opacity: 0, y: -30 },
  animate: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: 30 }
}
