import { useEffect, useState } from "react"

export default function useConfig(url) {
  const [config, setConfig] = useState()
  const [error, setError] = useState()

  useEffect(() => {
    ;(async () => {
      try {
        const response = await fetch(url)
        const json = await response.json()
        const path = url.split("/").slice(0, -1).join("/")
        setConfig(json.map(([fw, rv]) => [`${path}/${fw}`, `${path}/${rv}`]))
      } catch (err) {
        setError(err)
      }
    })()
  }, [url])

  return {
    config,
    error,
    loading: !config && !error
  }
}
