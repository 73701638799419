import styled from "styled-components"

export default function IconButton(props) {
  const { onClick, className, icon, active = true } = props
  return (
    <Button active={active} className={className} onClick={onClick}>
      <img src={icon} alt=""></img>
    </Button>
  )
}

const Button = styled.button`
  opacity: ${props => (props.active ? 1 : 0.2)};
  width: 3em;
  height: 3em;
  border: none;
  outline: none;
  background-color: transparent;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0.5em;
  transition: all 0.2s ease-out;
  border-radius: 50%;
  img {
    width: 100%;
  }
  &:hover {
    opacity: 0.6;
  }
`
