import React, { useState, useEffect, useRef } from "react"
import styled from "styled-components"
import { AnimatePresence, motion } from "framer-motion"
import { device } from "theme/device"
import { useStore } from "state/store"
import { MAPPED_PROPS } from "api/typeMappers"
import IconButton from "components/Buttons/IconButton"
import Loader from "components/Loader"
import CloseIcon from "assets/images/icons/close.svg"
import EmailIcon from "./email.svg"
import LinkIcon from "./copy.svg"
import { useForm } from "react-hook-form"
import "./ShareDialog.css"
import "react-simple-keyboard/build/css/index.css"
import "./VMIKeyboard.css"
import useCheckInternetConnection from "utils/useCheckInternetConnection"

const bccAddress = {
  Test1: "matthewosborne@live.com",
  Test2: "dsgnrm@gmail.com"
}

export default function ShareDialog() {
  const favourites = useStore(state => state.favourites)
  const openShareDialog = useStore(state => state.openShareDialog)
  const setOpenShareDialog = useStore(state => state.setOpenShareDialog)

  const [sending, setSending] = useState(false)

  const [nameState, setNameState] = useState("")
  const [emailState, setEmailState] = useState("")

  const { isConnected } = useCheckInternetConnection()

  // eslint-disable-next-line
  const [bcc, setBcc] = useState([])

  const favouriteIDs = []
  favourites.map(f => favouriteIDs.push(f[MAPPED_PROPS.PLOT]))

  const encoded = btoa(`${favouriteIDs.join(",")}`)
  const url = `${window.location.origin}/share?${encoded}`

  const keyboard = useRef()

  const { register } = useForm()

  useEffect(() => {
    return () => {
      setNameState("")
      setEmailState("")
    }
  }, [openShareDialog])

  useEffect(() => {
    if (keyboard.current) {
      const hideButton = keyboard.current.getButtonElement("{hide}")

      hideButton.addEventListener("click", () => {
        keyboard.current.keyboardDOM.classList.add("hidden")
      })
    }
  })

  const handleSendemail = e => {
    setSending(true)
    e.preventDefault()
    const emailURL =
      "https://qqo4y7jd7c.execute-api.eu-west-2.amazonaws.com/dev/send"
    const apiKey = "SuslEuh0MU9WUeGbEjF6N83RLw84ikWq5pQqw7CK"
    fetch(emailURL, {
      method: "POST",
      mode: "no-cors",
      headers: {
        "x-api-key": apiKey,
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        from: "sales@willowwalk-app.co.uk",
        to: [emailState, "Ilona.Barkane@peabody.org.uk"],
        bcc: [
          bccAddress[bcc[0]],
          bccAddress[bcc[1]],
          "Ilona.Barkane@peabody.org.uk"
        ],
        template: "willowwalk",
        language: "en",
        project: "willowwalk",
        data: {
          firstname: nameState.split(" ")[0],
          lastname: nameState.split(" ")[1],

          link: url
        }
      })
    }).then(() => {
      setSending(false)
    })
  }

  const copyToClipboard = string => {
    let textarea
    let result

    try {
      textarea = document.createElement("textarea")
      textarea.setAttribute("readonly", true)
      textarea.setAttribute("contenteditable", true)
      textarea.style.position = "fixed"
      textarea.value = string
      document.body.appendChild(textarea)
      textarea.focus()
      textarea.select()
      const range = document.createRange()
      range.selectNodeContents(textarea)
      const sel = window.getSelection()
      sel.removeAllRanges()
      sel.addRange(range)
      textarea.setSelectionRange(0, textarea.value.length)
      result = document.execCommand("copy")
    } catch (err) {
      result = null
    } finally {
      document.body.removeChild(textarea)
    }
    if (!result) {
      const isMac = navigator.platform.toUpperCase().indexOf("MAC") >= 0
      const copyHotkey = isMac ? "⌘C" : "CTRL+C"
      result = prompt(`Press ${copyHotkey}`, string)
      if (!result) {
        return false
      }
    }
    setOpenShareDialog(false)
    setSending(false)
    return true
  }

  const handleEmailSend = () => {
    copyToClipboard(url)
  }

  const handleClose = () => {
    setOpenShareDialog(false)
    setNameState("")
    setEmailState("")
  }

  const handleChangeNameState = e => {
    setNameState(e.target.value)
  }

  const handleChangeEmailState = e => {
    setEmailState(e.target.value)
  }

  return (
    <>
      <AnimatePresence>
        {openShareDialog && (
          <DialogHolder>
            <motion.form
              className="share-form"
              transition={{ duration: 0.5 }}
              initial={{ opacity: 0, y: -30 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 30 }}
            >
              <CloseButton
                style={{ pointerEvents: "auto" }}
                icon={CloseIcon}
                onClick={e => {
                  e.stopPropagation()
                  e.preventDefault()
                  handleClose()
                }}
              />
              <Content>
                {isConnected !== null ? (
                  isConnected === "online" ? (
                    <>
                      <Title>Share</Title>
                      <ItemHolder>
                        <EmailGroup>
                          <input
                            id="name"
                            value={nameState}
                            {...register("name", { required: true })}
                            className="share-input"
                            autoComplete="off"
                            inputMode="text"
                            onChange={e => {
                              handleChangeNameState(e)
                            }}
                            placeholder="Name"
                          />

                          <input
                            id="email"
                            value={emailState}
                            className="share-input"
                            autoComplete="off"
                            inputMode="text"
                            type="email"
                            onChange={e => {
                              handleChangeEmailState(e)
                            }}
                            placeholder="Email"
                          />
                        </EmailGroup>

                        <Controls>
                          {sending ? (
                            <EmailWaiter />
                          ) : (
                            <input
                              onClick={handleSendemail}
                              className="share-control"
                              type="image"
                              src={EmailIcon}
                              border="0"
                              alt="Submit"
                            />
                          )}
                          <img
                            className="share-control"
                            src={LinkIcon}
                            alt="Copy link"
                            onClick={handleEmailSend}
                          />
                        </Controls>
                      </ItemHolder>
                    </>
                  ) : (
                    <OfflineMessage>
                      Sorry, this feature is not available offline.
                    </OfflineMessage>
                  )
                ) : (
                  ""
                )}
              </Content>
            </motion.form>
          </DialogHolder>
        )}
      </AnimatePresence>
    </>
  )
}

const OfflineMessage = styled.div`
  font-size: 3em;
  color: white;
  text-align: center;
  display: flex;
  height: 4em;
  align-items: center;
`

const DialogHolder = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Brother-1816-Book;
  width: 100%;
  height: 100%;
  background: #000000aa;
  @media ${device.mobileL} {
    font-size: 0.9em;
  }
`

const CloseButton = styled(IconButton)`
  position: absolute;
  top: 0.1em;
  right: 0.1em;
  z-index: 1;
  width: 2.5em;
  height: 2.5em;
  background-color: transparent;
`

const EmailWaiter = styled(Loader)`
  width: 2em;
  height: 2em;
`

const Content = styled.div`
  display: flex;
  flex-direction: row;
  padding: 1em;
  @media ${device.pad} {
    flex-direction: column;
  }
`
const Title = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 3em;
  color: white;
  padding: 1em 1em;
  text-transform: uppercase;
  border-right: 1px solid #c7a791;
  @media ${device.pad} {
    border-right: none;
  }
`

const ItemHolder = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5em;
  padding: 1em 1.5em 0.5em 1.5em;
  font-size: 1.2em;
`
const EmailGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2em;

  & span {
    color: #d15151;
    margin-top: -35px;
    font-size: 0.8em;
    font-family: Futura-Book;
  }
`

const Controls = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 1vw;
`
