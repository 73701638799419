import React from "react"
import Orbits from "components/Orbits"
import { AnimatePresence, motion } from "framer-motion"
import styled from "styled-components"

export default function OrbitsPresentation() {
  return (
    <AnimatePresence>
      <OrbitsHolder
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -50 }}
        transition={{ duration: 0.6 }}
      >
        <Orbits configUrl={"assets/orbits/orbits.json"} />
      </OrbitsHolder>
    </AnimatePresence>
  )
}

const OrbitsHolder = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
`
