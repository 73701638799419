import { useEffect, useState } from "react"
import { useMeasure } from "react-use"
import styled from "styled-components"

import TargetIcon from "assets/images/home/target.svg"
import { device } from "theme/device"
import HotspotDialog from "./HotspotDialog"
import { useStore } from "state/store"
import { HotspotData } from "./HotspotData"
import { VIDEO_HEIGHT, VIDEO_RATIO, VIDEO_WIDTH } from "../Orbits.config"

export default function HotspotLayer() {
  const openDialogOrbits = useStore(state => state.openDialogOrbits)
  const setOpenDialogOrbits = useStore(state => state.setOpenDialogOrbits)
  const orbitsVideoIndex = useStore(state => state.orbitsVideoIndex)
  const setHotspotIndex = useStore(state => state.setHotspotIndex)
  const hotspotIndex = useStore(state => state.hotspotIndex)
  const showHotspots = useStore(state => state.showHotspots)
  const setShowHotspots = useStore(state => state.setShowHotspots)

  const [holderRef, holderMeasure] = useMeasure()
  const [width, setWidth] = useState(0)
  const [height, setHeight] = useState(0)
  const [posRatio, setPosRatio] = useState(1)

  const handleHotspotClick = index => {
    if (index === hotspotIndex) {
      setOpenDialogOrbits(!openDialogOrbits)
    } else {
      setHotspotIndex(index)
      setOpenDialogOrbits(true)
    }
  }

  useEffect(() => {
    if (!showHotspots) {
      setTimeout(() => {
        setShowHotspots(true)
      }, 1500)
    }
    // eslint-disable-next-line
  }, [showHotspots])

  useEffect(() => {
    const ratio = holderMeasure.width / holderMeasure.height
    if (ratio > VIDEO_RATIO) {
      const pRatio = holderMeasure.height / VIDEO_HEIGHT
      setPosRatio(pRatio)
      setWidth(VIDEO_WIDTH * pRatio)
      setHeight(holderMeasure.height)
    } else {
      const pRatio = holderMeasure.width / VIDEO_WIDTH
      setPosRatio(pRatio)
      setWidth(holderMeasure.width)
      setHeight(VIDEO_HEIGHT * pRatio)
    }
  }, [holderMeasure])

  return (
    <Holder
      ref={holderRef}
      onClick={() => {
        setOpenDialogOrbits(false)
      }}
    >
      <ResizeHolder width={width} height={height}>
        {HotspotData[orbitsVideoIndex].map((d, index) => (
          <Hotspot
            key={index}
            x={d.pos.x * posRatio}
            y={d.pos.y * posRatio}
            z={index}
            onClick={e => {
              e.stopPropagation()
              handleHotspotClick(index)
            }}
            visible={showHotspots}
          />
        ))}
      </ResizeHolder>
      <HotspotDialog />
    </Holder>
  )
}

const Holder = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`

const ResizeHolder = styled.div`
  position: relative;
  width: ${props => props.width}px;
  height: ${props => props.height}px;
  /* background-color: #ff00ff33; */
`

const Hotspot = styled.button`
  position: absolute;
  top: ${props => props.y}px;
  left: ${props => props.x}px;
  z-index: ${props => props.z};
  width: 3.5em;
  height: 3.5em;
  border-radius: 50%;
  border: none;
  outline: none;
  background-color: transparent;
  background-image: url(${TargetIcon});
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
  opacity: ${props => (props.visible ? 1 : 0)};
  transition: all 0.3s ease-in-out;
  animation: pulse 2s infinite;
  @keyframes pulse {
    0% {
      box-shadow: 0 0 0 0 rgb(255 255 0 / 70%);
    }
    70% {
      box-shadow: 0 0 0 25px rgb(255 255 0 / 0%);
    }
    100% {
      box-shadow: 0 0 0 0 rgb(255 255 0 / 0%);
    }
  }
  &:hover {
    opacity: 0.6;
  }
  @media ${device.mobileL} {
    width: 2em;
    height: 2em;
  }
`
