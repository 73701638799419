import { useRef } from "react"
import PageTransitionHolder from "components/PageTransitionHolder"
import styled from "styled-components"
import BgImage from "assets/images/aboutus/background.jpg"
import PeabodyImage from "assets/images/aboutus/peabody.svg"
import IconButton from "components/Buttons/IconButton"
import BackIcon from "assets/images/icons/marker.svg"

import { useMeasure } from "react-use"

export default function AboutUs() {
  const part1Ref = useRef()
  const part2Ref = useRef()
  const [cover1Ref, measure1] = useMeasure()
  const [cover2Ref, measure2] = useMeasure()

  const handleCover1 = () => {
    part1Ref.current.scrollTo({ top: measure1.height, behavior: "smooth" })
    part2Ref.current.scrollTo({ top: measure1.y, behavior: "smooth" })
  }

  const handleCover2 = () => {
    part2Ref.current.scrollTo({ top: measure2.height, behavior: "smooth" })
    part1Ref.current.scrollTo({ top: measure1.y, behavior: "smooth" })
  }

  const scrollBack = part => {
    part.current.scrollTo({ top: measure1.y, behavior: "smooth" })
  }

  return (
    <PageTransitionHolder>
      <Holder>
        <PartHolder ref={part1Ref}>
          <CoverHolder ref={cover1Ref} onClick={handleCover1}>
            PEABODY
          </CoverHolder>
          <Content style={{ zIndex: 106 }}>
            <BackButton icon={BackIcon} onClick={() => scrollBack(part1Ref)} />
            <PeabodyIcon />
            <Comment>
              Peabody creates great places where people want to live, adding
              value by upholding high design standards, building sustainable,
              high-quality new homes that stand the test of time and making a
              positive difference through ongoing commitment to our communities.
            </Comment>
            <Item>
              <ItemTitle>building history</ItemTitle>
              <ItemText>
                Formed 160 years ago by the American financier and
                philanthropist George Peabody, Peabody is one of the UK’s oldest
                and largest housing associations. Together with Catalyst Housing
                who joined the Group in 2022, we are responsible for more than
                104,000 homes across London and the Home Counties.
              </ItemText>
            </Item>
            <Item>
              <ItemTitle>social impact</ItemTitle>
              <ItemText>
                In line with our social purpose, Peabody re-invests its surplus
                to provide more homes and services. We deliver services to
                220,000 residents, supporting customers and the wider
                communities in which we work. We focus on those who need our
                help the most, working with people and communities to build
                resilience and promote wellbeing. We invest around £11m each
                year through our Community Foundation, to support people to be
                healthier, wealthier and happier.
              </ItemText>
            </Item>
            <Item>
              <ItemTitle>customer focus</ItemTitle>
              <ItemText>
                Over 90% of customers said they would recommend Peabody. Our
                customers’ feedback helps shape the design of our homes and
                community services based on the needs of each neighbourhood. We
                value our customers and listen to what they have to tell us.
                Peabody are proud to have achieved ‘Gold’ in the independent
                customer satisfaction awards three times in a row and stive for
                excellence in all that we do.
              </ItemText>
            </Item>
            <Item>
              <ItemTitle>Quality Design & Sustainability </ItemTitle>
              <ItemText>
                Peabody’s reputation is one of the most respected in the housing
                industry, renowned for high quality, innovative design. We work
                with industry experts and continually monitor quality throughout
                the design, procurement and development process. We aim to make
                a positive impact on climate change for our residents,
                communities and the environment, with an ambition to achieve net
                zero carbon in our new and existing homes by 2050.
              </ItemText>
            </Item>
            <Item>
              <ItemTitle>Strong Growth </ItemTitle>
              <ItemText>
                With a growing pipeline, Peabody continues to invest in building
                quality new homes with high design standards. We create homes in
                great locations which are safe, easy to maintain and
                long-lasting. Peabody delivers long-term returns on investment.
                With an asset base of £8.3bn, Peabody is well placed to build
                thousands more top quality, well-maintained homes each year.
              </ItemText>
            </Item>
            <Item>
              <ItemTitle>award winning</ItemTitle>
              <ItemText>
                In recent years, Peabody has been recognised and awarded more
                than 60 prestigious awards, including the Sunday Times
                ‘Homebuilder of the Year’ and the Grand Prix award at the
                Evening Standard New Homes Awards. We also work with award
                winning partners who share our values, high design standards and
                focus on great customer experience.
              </ItemText>
            </Item>
          </Content>
        </PartHolder>
        <PartHolder ref={part2Ref}>
          <CoverHolder ref={cover2Ref} onClick={handleCover2}>
            SHARED
            <br />
            OWNERSHIP
          </CoverHolder>
          <Content>
            <BackButton icon={BackIcon} onClick={() => scrollBack(part2Ref)} />
            <PeabodyIcon />
            <Comment>
              Peabody creates great places where people want to live, adding
              value through long-term, patient investment. This historic
              organisation is known for upholding high design standards to
              create homes in great locations which are safe, easy to maintain
              and long-lasting.
            </Comment>
            <Item>
              <ItemTitle>Register your interest in Willow Walk </ItemTitle>
              <ItemText>
                Once you have found a place you love, register your interest
                with the Peabody team by completing an application form.
              </ItemText>
            </Item>
            <Item>
              <ItemTitle>Take a quick financial assessment </ItemTitle>
              <ItemText>
                We’ll need to make sure that you meet all the criteria. We’ll
                arrange for you to have an initial assessment with an
                Independent Mortgage Advisor (IMA) to understand a bit more
                about your financial position and whether Shared Ownership is
                suitable.
              </ItemText>
            </Item>
            <Item>
              <ItemTitle>View the home and reserve for £500 </ItemTitle>
              <ItemText>
                If you like what you see once you complete a viewing, you can
                reserve a home for £500.
              </ItemText>
            </Item>
            <Item>
              <ItemTitle>Offering you a home </ItemTitle>
              <ItemText>
                Within seven days of you making a reservation we will confirm
                whether we are able to offer you a Shared Ownership home with an
                offer letter.
              </ItemText>
            </Item>
            <Item>
              <ItemTitle>Full financial assessment </ItemTitle>
              <ItemText>
                Once you have been offered a property, you will be invited to
                meet an Independent Mortgage Advisor (IMA) from our selected
                panel of experts. During this meeting, they’ll do a more
                in-depth check to ensure you are able to afford the home, how
                big a share you can afford and to double-check you meet the
                eligibility criteria.
              </ItemText>
            </Item>
            <Item>
              <ItemTitle>Appoint a Solicitor & Mortgage Advisor</ItemTitle>
              <ItemText>
                You will need to formally instruct a solicitor and mortgage
                advisor on the day of your financial assessment. Once you’ve
                instructed a solicitor and mortgage advisor, you will need to
                complete an Offer Acceptance Form. It’s at this stage your
                reservation fee will be non-refundable up to completion.
              </ItemText>
            </Item>
            <Item>
              <ItemTitle>Obtain a mortgage</ItemTitle>
              <ItemText>
                You will need to submit a mortgage application within two
                working days of your financial assessment. You may need to book
                this in advance to your financial assessment to make sure you
                meet the timescales. An independent valuation will be carried
                out on behalf of the mortgage lender, who will present you with
                a mortgage offer if they a satisfied with the checks
              </ItemText>
            </Item>
            <Item>
              <ItemTitle>The paperwork </ItemTitle>
              <ItemText>
                Once we receive an offer acceptance form, we will send a
                Memorandum of Sale to your solicitors. After all legal enquiries
                have been satisfied, your solicitor will contact you to arrange
                exchange of contracts. Upon exchange, you’re legally bound to
                buy the home and Peabody is legally required to sell you the
                property.
              </ItemText>
            </Item>
            <Item>
              <ItemTitle>Completion</ItemTitle>
              <ItemText>
                On completion day, the money to buy your home is transferred to
                our solicitors. This is typically within 10 days of exchanging
                of contracts if your home is ready or longer if the home is
                still being built. Prior to your completion date, we will be in
                contact with you to arrange a time to collect your keys to your
                new Shared Ownership home.
              </ItemText>
            </Item>
          </Content>
        </PartHolder>
      </Holder>
    </PageTransitionHolder>
  )
}

const BackButton = styled(IconButton)`
  position: absolute;
  top: 0.5em;
  right: 0.5em;
  z-index: 9;
  width: 3em;
  filter: invert(83%) sepia(70%) saturate(1%) hue-rotate(109deg)
    brightness(107%) contrast(101%);
`

const Holder = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  color: white;
`

const CoverHolder = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  transition: all 0.5s ease;
  cursor: pointer;
  text-align: center;
  color: white;
  font-family: Brother-1816-Book;
  font-size: 2vw;
`

const PartHolder = styled.div`
  position: relative;
  width: 50%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  scroll-behavior: smooth;
  background-image: url(${BgImage});
  background-position: center;
  background-size: 200% auto;
  &::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }
  &:hover {
    ${CoverHolder} {
      background-color: #000000;
      opacity: 0.6;
    }
  }
`

const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  height: 100vh;
  padding: 2vw 2.5vw;
  background-color: #134729;
  position: relative;
  z-index: 105;
  overflow: auto;
  overflow-x: hidden;
`

const PeabodyIcon = styled.div`
  width: 20vw;
  height: 5vw;
  background-image: url(${PeabodyImage});
  background-position: center;
  background-size: 100% 100%;
  margin-top: 3.5em;
`

const Comment = styled.div`
  width: 20vw;
  font-family: LouvetteDeck-Regular;
  font-size: 0.9vw;
  line-height: 120%;
  color: #ffffff;
  text-align: justify;
  margin-top: 4em;
`
const Item = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1vw;
  width: 20vw;
`
const ItemTitle = styled.div`
  font-family: Brother-1816-Book;
  font-style: normal;
  font-weight: normal;
  font-size: 1vw;
  line-height: 120%;
  letter-spacing: 0.1em;
  color: #697f34;
  text-transform: uppercase;
`
const ItemText = styled.div`
  font-family: Brother-1816-Book;
  font-style: normal;
  font-weight: normal;
  font-size: 0.9vw;
  line-height: 130%;
  color: #ffffff;
  text-align: justify;
`
