import { MAPPED_PROPS } from "api/typeMappers"
import styled from "styled-components"
import { device } from "theme/device"
import { priceFormatter } from "utils/convertDataFormat"

function Pricetable({ apartment }) {
  return (
    <Prices>
      <h4>Pricing:</h4>
      <VDivider />
      <table>
        <tbody>
          <tr>
            <td>Full Value</td>
            <td>{priceFormatter.format(apartment[MAPPED_PROPS.FULL_VALUE])}</td>
          </tr>
          <tr>
            <td>Deposit Required</td>
            <td>
              {priceFormatter.format(apartment[MAPPED_PROPS.DEPOSIT_REQUIRED])}
            </td>
          </tr>
          <tr>
            <td>Share Price</td>
            <td>{priceFormatter.format(apartment[MAPPED_PROPS.PRICE])}</td>
          </tr>
          <tr>
            <td>Share Percentage</td>
            <td>{apartment[MAPPED_PROPS.SHARE_VALUE]}</td>
          </tr>
          <tr>
            <td>Monthly Cost</td>
            <td>
              {priceFormatter.format(apartment[MAPPED_PROPS.MONTHLY_COST])}
            </td>
          </tr>
          <tr>
            <td>Monthly Mortgage</td>
            <td>
              {priceFormatter.format(apartment[MAPPED_PROPS.MONTHLY_MORTGAGE])}
            </td>
          </tr>
          <tr>
            <td>Monthly Rent</td>
            <td>
              {priceFormatter.format(apartment[MAPPED_PROPS.MONTHLY_RENT])}
            </td>
          </tr>
          <tr>
            <td>Monthly Service Charge</td>
            <td>
              {priceFormatter.format(apartment[MAPPED_PROPS.MONTHL_YCHARGE])}
            </td>
          </tr>
          <tr>
            <td>Minimum Household Income</td>
            <td>
              {priceFormatter.format(
                apartment[MAPPED_PROPS.MIN_HOUSEHOLD_INCOME]
              )}
            </td>
          </tr>
          <tr>
            <td>Maximum Household Income</td>
            <td>
              {priceFormatter.format(
                apartment[MAPPED_PROPS.MAX_HOUSEHOLD_INCOME]
              )}
            </td>
          </tr>
        </tbody>
      </table>
    </Prices>
  )
}

export default Pricetable

const Prices = styled.div`
  /* border: red solid 2px; */
  margin-top: 15px;
  text-align: left;
  /* width: 90%; */
  display: flex;
  /* flex-shrink: 1; */
  height: 200px;
  flex-direction: column;
  gap: 0em;
  justify-content: flex-start;

  font-size: 1.2vh;
  h4 {
    font-family: LouvetteDeck-Regular;
    color: #65793b;
    margin: 0;
    padding: 0;
    font-size: 1.75em;
    /* border: red solid 2px; */
    /* line-height: 150%; */
  }
  table {
    /* border: red solid 2px; */
    font-family: Brother-1816-Book;
    color: #575756;
    line-height: 125%;
    border-collapse: separate;
    /* border-spacing: 2em 0; */
    /* min-width: 220px;/ */
  }
  @media ${device.laptop} {
    /* font-size: 1vw; */
  }
`
const VDivider = styled.div`
  width: 100%;
  max-height: 1px;
  /* height: 90%; */
  background-color: #eae0d3;
  /* margin: 0 1em; */
`
