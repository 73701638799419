import ThemeProvider from "./theme"
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom"
import { QueryClientProvider, QueryClient } from "react-query"
import { LandingRoute, PrimaryRoutes, ShareRoute } from "dataset/routes"
import { MiddleContainer } from "components/Containers"
import SideMenu from "components/SideMenu"
import { useStore } from "state/store"
import { useEffect } from "react"
import useCheckInternetConnection from "utils/useCheckInternetConnection"

const queryClient = new QueryClient()

export const ApartmentSheetURL =
  "https://docs.google.com/spreadsheets/d/e/2PACX-1vTkxD1JJ_x3-WW99vN3R0nqqIqnIwlHHc7nTP2yL6PrWwChYWr4ugzPAUMqhR_2vlqblT1C3abD8WrD/pub?gid=730947247&single=true&output=csv"

export default function App() {
  const setApartments = useStore(state => state.setApartments)
  const setMinPrice = useStore(state => state.setMinPrice)
  const setMaxPrice = useStore(state => state.setMaxPrice)
  const setPriceRange = useStore(state => state.setPriceRange)

  const isShare = useStore(state => state.isShare)
  const { isConnected } = useCheckInternetConnection()

  const fetchApartmentsCSV = () => {
    fetch(ApartmentSheetURL)
      .then(response => {
        if (!response.ok) return

        processCSV(response.text())
      })
      .catch(() => {})
  }

  useEffect(() => {
    if (isConnected !== null) {
      if (isConnected === "online") fetchApartmentsCSV()
      else if (isConnected === "offline") {
        const tempApt = JSON.parse(localStorage.getItem("apartmentsOffline"))

        const minPrice = Number(getMinPrice(tempApt)["Share Price"])
        const maxPrice = Number(getMaxPrice(tempApt)["Share Price"])

        setMinPrice(minPrice)
        setMaxPrice(maxPrice)
        setPriceRange([minPrice, maxPrice])

        setApartments(tempApt)
      }
    }
  }, [isConnected, setApartments])

  const processCSV = csvText => {
    csvText
      .then(text => {
        const data = text.split("\r\n").map(line => line.split(","))
        const header = data[0]
        const apartmentsLines = data.slice(1)
        const apartments = []

        for (let line of apartmentsLines) {
          const apartment = {}
          for (let x = 0; x < line.length; x++) {
            apartment[header[x]] = line[x]
          }

          apartments.push(apartment)
        }

        const minPrice = Number(getMinPrice(apartments)["Share Price"])
        const maxPrice = Number(getMaxPrice(apartments)["Share Price"])

        setMinPrice(minPrice)
        setMaxPrice(maxPrice)
        setPriceRange([minPrice, maxPrice])

        localStorage.setItem("apartmentsOffline", JSON.stringify(apartments))

        setApartments(apartments)
      })
      .catch(err => {
        console.log("Error on processing CSV text: ", err)
      })
  }

  return (
    <ThemeProvider>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <MiddleContainer>
            {!isShare && <SideMenu />}
            <Switch>
              <Route
                exact
                path={LandingRoute.path}
                component={LandingRoute.component}
              />
              <Route path={ShareRoute.path} component={ShareRoute.component} />
              {PrimaryRoutes.map(({ path, component }, index) => (
                <Route key={index} path={path} component={component}></Route>
              ))}
              <Redirect to={"/"} />
            </Switch>
          </MiddleContainer>
        </BrowserRouter>
      </QueryClientProvider>
    </ThemeProvider>
  )
}

function getMaxPrice(arr) {
  let max
  for (let i = 0; i < arr.length; i++) {
    if (
      max == null ||
      parseInt(arr[i]["Share Price"]) > parseInt(max["Share Price"])
    ) {
      max = arr[i]
    }
  }
  return max
}

function getMinPrice(arr) {
  let min
  for (let i = 0; i < arr.length; i++) {
    if (
      min == null ||
      parseInt(arr[i]["Share Price"]) < parseInt(min["Share Price"])
    ) {
      min = arr[i]
    }
  }
  return min
}
