import config from "./config.json"
import io from "socket.io-client"
import utils from "./utils"

const c = config.lightswarmConfig
const url = `${c.ipAddress}:${c.socketsPort}`
let socket = io(url)

export const lightsOn = async (
  apartments,
  favourites = { isFavourites: false, dimmedUnits: [] }
) => {
  let apartmentsOn = await utils.parseApartments(apartments)

  if (favourites.isFavourites) {
    apartmentsOn = await utils.lightUpFavourites(
      apartments,
      favourites.dimmedUnits
    )
  } else {
    apartmentsOn = await utils.parseApartments(apartments)
  }

  try {
    socket.emit("lights", apartmentsOn)
  } catch (err) {
    console.log(err)
  }
}

export const allLightsOn = apartments => {
  try {
    socket.emit("lights", apartments)
  } catch (err) {
    console.log(err)
  }
}

export const allLightsOff = () => {
  try {
    socket.emit("lights", [])
  } catch (err) {
    console.log(err)
  }
}
