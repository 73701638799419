import styled from "styled-components"
import { motion } from "framer-motion"
import { useStore } from "state/store"
import { HotspotData } from "./HotspotData"
import SideImage from "assets/images/home/hotspot_dlg.svg"
import { device } from "theme/device"

export default function HotspotDialog() {
  const openDialogOrbits = useStore(state => state.openDialogOrbits)
  const hotspotIndex = useStore(state => state.hotspotIndex)
  const orbitIndex = useStore(state => state.orbitIndex)

  const hotspotData =
    HotspotData[orbitIndex === HotspotData.length ? 0 : orbitIndex]?.[
      hotspotIndex
    ]
  if (hotspotData) {
    return (
      <DlgHolder
        initial="init"
        variants={{
          init: {
            opacity: 0,
            y: 30
          },
          show: {
            opacity: 1,
            y: 0
          },
          hidden: {
            opacity: 0,
            y: -30
          }
        }}
        transition={{
          type: "spring",
          damping: 12,
          stiffness: 120,
          delay: 0,
          duration: 0.2
        }}
        animate={openDialogOrbits ? "show" : "hidden"}
      >
        <div>
          <img src={SideImage} alt=""></img>
        </div>
        <div>
          <DlgTitle>{hotspotData.title}</DlgTitle>
          <DlgDesc>{hotspotData.description}</DlgDesc>
        </div>
      </DlgHolder>
    )
  } else return null
}

const DlgHolder = styled(motion.div)`
  position: fixed;
  bottom: 10%;
  left: calc(50% - 20em);
  z-index: 10;
  display: flex;
  width: 40em;
  background-color: #929b49;
  padding: 1em;
  gap: 0.5em;
  & img {
    width: 100%;
  }
  & div {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 0.5em;
  }
  @media ${device.mobileL} {
    width: 90%;
  }
`
const DlgTitle = styled.p`
  font-size: 2em;
  font-family: LouvetteDeck-Regular;
  color: #1e493b;
  font-weight: bold;
  line-height: 130%;
  margin: 0;
  padding: 0;
`

const DlgDesc = styled.p`
  font-size: 1em;
  font-family: Brother-1816-Book;
  color: white;
  margin: 0;
  padding: 0;
  line-height: 150%;
`
