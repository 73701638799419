import styled from "styled-components"
import { Link } from "react-router-dom"

import { useStore } from "state/store"
import { device } from "theme/device"
import ReactPlayer from "react-player"
import PageTransitionHolder from "components/PageTransitionHolder"
import VideoBG from "assets/images/splash/test.jpg"
import Animation from "assets/images/splash/splash_animation.mp4"
import LogoIcon from "assets/images/splash/logo.svg"
import Cover from "components/Orbits/Cover"

export default function Landing() {
  const setMenuVisible = useStore(state => state.setMenuVisible)

  return (
    <PageTransitionHolder>
      <Holder>
        <HomeLink
          to="/home"
          onClick={() => {
            setMenuVisible(true)
          }}
        >
          ENTER
        </HomeLink>
        <Logo src={LogoIcon} alt="" />
        <Cover>
          <ReactPlayer
            loop={true}
            url={Animation}
            width="100%"
            height="100%"
            playing={true}
            muted={true}
            playsinline={true}
            config={{
              file: { attributes: { poster: "noposter" } }
            }}
          />
          <img width="100%" height="100%" src={VideoBG} alt="" />
        </Cover>
      </Holder>
    </PageTransitionHolder>
  )
}

const Holder = styled.div`
  width: 100%;
  height: 100%;
  background-image: url(${Animation});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`

const Logo = styled.img`
  z-index: 1;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  min-width: 300px;
  width: 60%;
  max-width: 600px;
  @media ${device.mobileL} {
    font-size: 2em;
  }
`

const HomeLink = styled(Link)`
  z-index: 1;
  position: absolute;
  font-family: DistinctStyleScript;
  font-size: 3em;
  left: 50%;
  bottom: 10%;
  transform: translate(-50%, -50%);
  color: #fff;
  letter-spacing: 0.3em;
  @media ${device.mobileL} {
    font-size: 2em;
  }
`
