import { useMemo, useState } from "react"

import { useStore } from "state/store"

export default function usePairedSegments(segments, defaultIndex) {
  const pairs = useMemo(
    () =>
      segments.map((_, i) => ({
        fw: segments[i][0],
        rv: segments[prevIndex(i, segments)][1]
      })),
    [segments]
  )
  const videos = useMemo(() => segments.flat(), [segments])

  const [index, setIndex] = useState(defaultIndex)
  const [direction, setDirection] = useState(1)
  const [playing, setPlaying] = useState()

  const orbitIndex = useStore(state => state.orbitIndex)
  const setOrbitIndex = useStore(state => state.setOrbitIndex)

  const pair = pairs[index]
  const current = direction === -1 ? pair.rv : pair.fw

  return {
    index,
    videos,
    current,
    playing,
    left: pair.rv,
    right: pair.fw,
    onPlay: video => {
      let idx = orbitIndex

      if (direction !== (video === pair.rv ? -1 : 1)) {
        setOrbitIndex(Math.abs(idx))

        setTimeout(() => {
          if (video === pair.rv) {
            let prIndex = prevIndex(orbitIndex, segments)
            if (prIndex === 0) prIndex = 5
            setOrbitIndex(Math.abs(prIndex))
          }

          if (video === pair.fw) {
            setOrbitIndex((orbitIndex % 5) + 1)
          }
        }, 1000)
      } else {
        if (video === pair.rv) {
          let prIndex = prevIndex(orbitIndex, segments)
          if (prIndex === 0) prIndex = 5
          setOrbitIndex(Math.abs(prIndex))
        }

        if (video === pair.fw) {
          setOrbitIndex((orbitIndex % 5) + 1)
        }
      }

      setDirection(video === pair.rv ? -1 : 1)

      setTimeout(() => {
        setPlaying(video)
      })
    },
    onEnd: () => {
      setPlaying()
      setIndex(
        direction === -1 ? prevIndex(index, pairs) : nextIndex(index, pairs)
      )
    }
  }
}

const prevIndex = (current, arr) => {
  return current === 0 ? arr.length - 1 : current - 1
}

const nextIndex = (current, arr) => {
  return (current + 1) % arr.length
}
