import AboutUs from "pages/AboutUs"
import ApartmentFinder from "pages/ApartmentFinder"
import Gallery from "pages/Gallery"
import Home from "pages/Home"
import Location from "pages/Location"
import ShowHome from "pages/ShowHome"
import Favourites from "pages/Favourites"
import Film from "pages/Film"
import Share from "pages/Share"
import Landing from "pages/Landing"
import OrbitsPresentation from "pages/OrbitsPresentation"

export const PrimaryRoutes = [
  { label: "Home", path: "/home", component: Home },
  { label: "Orbits", path: "/orbits", component: OrbitsPresentation },
  {
    label: "Location",
    path: "/location",
    component: Location
  },
  {
    label: "Apartment Finder",
    path: "/finder",
    component: ApartmentFinder
  },
  {
    label: "Favourites",
    path: "/favourites",
    component: Favourites
  },
  {
    label: "Show Home",
    path: "/showhome",
    component: ShowHome
  },
  { label: "Film", path: "/film", component: Film },
  {
    label: "Gallery",
    path: "/gallery",
    component: Gallery
  },
  { label: "About", path: "/aboutus", component: AboutUs },
  { label: "up", path: "/", component: null }
]

export const LandingRoute = { label: "Landing", path: "/", component: Landing }
export const ShareRoute = { label: "Share", path: "/share", component: Share }
