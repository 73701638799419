import { LOCATION_TAB_DATA } from "dataset/location"
import { MAPPED_PROPS } from "api/typeMappers"
import create from "zustand"

export const useStore = create((set, get) => ({
  // START Lightswarm
  lwActiveFloor: 99,
  setLwActiveFloor: v => set({ lwActiveFloor: v }),

  // END Lightswarm

  orbitIndex: 5,
  setOrbitIndex: v => set({ orbitIndex: v }),
  //Main menu
  menuVisible: true,
  setMenuVisible: v => set({ menuVisible: v }),
  navOpen: false,
  setNavOpen: v => set({ navOpen: v }),

  isVideoReady: false,
  setIsVideoReady: v => set({ isVideoReady: v }),

  orbitAnimationDone: false,
  setOrbitAnimationDone: v => set({ orbitAnimationDone: v }),

  //Apartment Finder
  apartments: [],
  setApartments: v => set({ apartments: v }),
  activeFloor: null,
  setActiveFloor: v => set({ activeFloor: v }),

  activeApartment: null,
  setActiveApartment: v => set({ activeApartment: v }),
  apartRotation: 0,
  setApartRotation: v => set({ apartRotation: v }),

  minPrice: 0,
  setMinPrice: v => set({ minPrice: v }),
  maxPrice: 0,
  setMaxPrice: v => set({ maxPrice: v }),

  priceRange: [0, 0],
  setPriceRange: v => set({ priceRange: v }),

  selectedBedrooms: [false, false, false, false],
  setSelectedBedrooms: v => set({ selectedBedrooms: v }),

  selectedAspects: [false, false, false, false],
  setSelectedAspects: v => set({ selectedAspects: v }),

  resetFilter: () =>
    set({
      priceRange: [get().minPrice, get().maxPrice],
      selectedBedrooms: [false, false, false, false],
      selectedAspects: [false, false, false, false]
    }),

  //Favourites
  openShareDialog: false,
  setOpenShareDialog: v => set({ openShareDialog: v }),
  openCompareDialog: false,
  setOpenCompareDialog: v => set({ openCompareDialog: v }),

  favourites: [],
  setFavourites: favourites => set(() => ({ favourites })),
  toggleFavourites: apartment =>
    set(state => ({
      favourites: state.favourites.some(
        e => e[MAPPED_PROPS.PLOT] === apartment[MAPPED_PROPS.PLOT]
      )
        ? state.favourites.filter(
            c => apartment[MAPPED_PROPS.PLOT] !== c[MAPPED_PROPS.PLOT]
          )
        : [...state.favourites, apartment]
    })),

  firstDustbin: null,
  setFirstDustbin: v => set({ firstDustbin: v }),
  secondDustbin: null,
  setSecondDustbin: v => set({ secondDustbin: v }),

  //Orbits
  orbitsVideoIndex: 0,
  setOrbitsVideoIndex: v => set({ orbitsVideoIndex: v }),
  hotspotIndex: 0,
  setHotspotIndex: v => set({ hotspotIndex: v }),
  openDialogOrbits: false,
  setOpenDialogOrbits: v => set({ openDialogOrbits: v }),
  showHotspots: true,
  setShowHotspots: v => set({ showHotspots: v }),

  //Gallery
  isCarousel: true,
  setIsCarousel: v => set({ isCarousel: v }),
  openGalleryDialog: false,
  setOpenGalleryDialog: v => set({ openGalleryDialog: v }),
  selectedGallery: null,
  setSelectedGallery: v => set({ selectedGallery: v }),

  //Location
  currentTabLocation: LOCATION_TAB_DATA[2].id,
  setCurrentTabLocation: v => set({ currentTabLocation: v }),
  openTimeLocation: false,
  setOpenTimeLocation: v => set({ openTimeLocation: v }),

  isShare: false,
  setIsShare: v => set({ isShare: v })
}))
