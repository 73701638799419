import styled from "styled-components"
import { device } from "theme/device"
import { formatPlotName } from "utils/hooks"
import { MAPPED_PROPS } from "api/typeMappers"

export default function RoomCard({ className, plotNumber, apartment }) {
  const floor = formatPlotName(apartment?.[MAPPED_PROPS.PLOT]).split(".")[0]

  const typeLetter = apartment?.[MAPPED_PROPS.TYPE]
    .split("")
    .pop()
    .toLowerCase()

  return (
    <CardHolder className={className}>
      <ApartID>{`B${plotNumber}`}</ApartID>
      <BedFloorGroup>
        <BedType>{apartment[MAPPED_PROPS.BEDS].charAt(0)} BED </BedType>
        <FloorID> Floor {floor}</FloorID>
      </BedFloorGroup>
      <Preview
        src={`assets/apartment/floorplans/type_${typeLetter}_floor_plan.svg`}
      />
    </CardHolder>
  )
}

const CardHolder = styled.div`
  position: relative;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  background-color: white;
  pointer-events: initial;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 1vw;
  gap: 0.5vw;
`
const ApartID = styled.div`
  font-family: DistinctStyleScript;
  font-weight: bold;
  font-size: 2vw;
  letter-spacing: 0.1em;
  color: #0f3e25;
  margin: 0;
  padding: 0;
  padding-left: 1vw;
  @media ${device.pad} {
    font-size: 7vw;
  }
`
const BedFloorGroup = styled.div`
  display: flex;
  gap: 1em;
`
const BedType = styled.p`
  font-family: Brother-1816-Book;
  font-size: 1em;
  line-height: 110%;
  color: #32503d;
  margin: 0;
  padding: 0;
`
const FloorID = styled.p`
  font-family: Brother-1816-Book;
  font-size: 1em;
  line-height: 110%;
  color: #5e7768;
  margin: 0;
  padding: 0;
`
const Preview = styled.img`
  width: 100%;
`
