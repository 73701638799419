import styled from "styled-components"
import { motion } from "framer-motion"
import { MenuItem } from "./MenuItem"
import { PrimaryRoutes } from "dataset/routes"
import { device } from "theme/device"

const variants = {
  open: {
    transition: {
      staggerChildren: 0.07,
      delayChildren: 0.2
    }
  },
  closed: {
    transition: {
      staggerChildren: 0.05,
      staggerDirection: -1
    }
  }
}

export const Navigation = ({ toggle }) => (
  <List variants={variants}>
    {PrimaryRoutes.map(({ label, path }, i) => {
      if (path !== "/orbits")
        return <MenuItem toggle={toggle} label={label} path={path} key={i} />
    })}
  </List>
)

const List = styled(motion.ul)`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: space-around;
  margin: 0;
  padding: 2em;
  width: 100%;
  height: 100%;
  gap: 1em;
  @media ${device.pad} {
    text-align: center;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`
