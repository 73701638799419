import { useDrop } from "react-dnd"

const style = {
  color: "black",
  padding: "1rem",
  textAlign: "center",
  fontSize: "1rem",
  lineHeight: "normal",
  float: "left"
}

export const ItemTypes = {
  BOX: "box"
}

export const Dustbin = ({ name, className, children }) => {
  const [{ canDrop, isOver }, drop] = useDrop(() => ({
    accept: ItemTypes.BOX,
    drop: () => ({ name: name }),
    collect: monitor => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop()
    })
  }))
  const isActive = canDrop && isOver
  let backgroundColor = "#fff"
  if (isActive) {
    backgroundColor = "#C2AD9C"
  } else if (canDrop) {
    backgroundColor = "#aaaaaa"
  }
  return (
    <div
      ref={drop}
      role={"Dustbin"}
      style={{ ...style, backgroundColor }}
      className={className}
    >
      {children}
    </div>
  )
}
