import { MAPPED_PROPS } from "api/typeMappers"
import React, { useEffect } from "react"
import { useStore } from "state/store"
import styled from "styled-components"
import { useAvailableApartments } from "utils/hooks"

export default function FloorButtons({ levels, handleClick }) {
  const activeFloor = useStore(state => state.activeFloor)
  const setLwActiveFloor = useStore(state => state.setLwActiveFloor)
  const activeFloors = []

  const availableApartments = useAvailableApartments()

  availableApartments.map(ap => activeFloors.push(ap[MAPPED_PROPS.FLOOR]))

  const floorSet = new Set(activeFloors)

  return (
    <Holder>
      {levels.map((v, i) => (
        <Item
          key={i}
          hasAp={floorSet.has(String(levels.length - i + 1))}
          active={activeFloor === i}
          onClick={() => {
            setLwActiveFloor(levels.length - i + 1)
            handleClick(v)
          }}
        >
          {levels.length - i + 1}
        </Item>
      ))}
    </Holder>
  )
}

const Holder = styled.div`
  position: absolute;
  top: 50%;
  right: 2em;
  z-index: 3;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  overflow-y: auto;
  max-height: 80%;
  transform: translate(0, -50%);
`

const Item = styled.div`
  width: 3.5rem;
  height: 3.5rem;
  border: ${props =>
    props.active
      ? "1px solid #1F3D29"
      : props.hasAp
      ? "1px solid #1f3d293b"
      : "1px solid #1F3D2900"};
  cursor: pointer;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #1f3d29;
  transition: all 0.2s ease-out;
  font-size: 1.3em;
  font-family: Brother-1816-Book;
  &:hover {
    background-color: #6e7e4533;
  }
`
