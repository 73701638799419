import { Suspense, useState } from "react"
import styled from "styled-components"
import { AnimatePresence, motion } from "framer-motion"

import { MAPPED_PROPS } from "api/typeMappers"
import { useStore } from "state/store"
import { device } from "theme/device"
import { isMobile, isTablet } from "react-device-detect"

import IconButton from "components/Buttons/IconButton"
import Viewer360 from "components/Viewer360"
import PrevButton from "components/Buttons/PrevButton"

import MarkerIcon from "assets/images/icons/marker.svg"
import SpecIcon from "assets/images/icons/icon-zoom.svg"
import HeartIcon from "assets/images/icons/heart.svg"
import VDividerIcon from "assets/images/icons/divider_v.svg"
import SpecImage from "assets/images/card/specification.svg"
import CompassIcon from "assets/images/card/willow-walk-compass.svg"
import Pricetable from "./components/Pricetable"
import { formatPlotName } from "utils/hooks"

export const FLOORS = {
  1: "first",
  2: "second",
  3: "third",
  4: "fourth",
  5: "fifth",
  6: "sixth",
  7: "seventh",
  8: "eighth",
  9: "ninth",
  10: "tenth",
  11: "eleventh",
  12: "twelfth",
  13: "thirteenth",
  14: "fourteenth",
  15: "fifteenth",
  16: "sixteenth",
  17: "seventeenth",
  18: "eighteenth",
  19: "nineteenth",
  20: "twentieth",
  21: "twenty-first",
  22: "twenty-second",
  23: "twenty-third",
  24: "twenty-fourth",
  25: "twenty-fifth",
  26: "twenty-sixth",
  27: "twenty-seventh",
  28: "twenty-eighth",
  29: "twenty-ninth",
  30: "thirtieth",
  31: "thirty-first"
}

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}
export default function ApartmentCard({
  className,
  isShare = false,
  favVisible = true,
  apartment,
  isCompare
}) {
  const setActiveApartment = useStore(state => state.setActiveApartment)
  const favourites = useStore(state => state.favourites)
  const toggleFavourites = useStore(state => state.toggleFavourites)
  const isActive =
    !isShare &&
    favourites.some(e => e[MAPPED_PROPS.PLOT] === apartment[MAPPED_PROPS.PLOT])

  const isNotMobisPad =
    isMobile &&
    !isTablet &&
    !window.screen?.orientation?.type.includes("landscape")

  const bedrooms = apartment?.[MAPPED_PROPS.BEDS].charAt(0)
  const [specVisible, setSpecVisible] = useState(false)
  const [panoVisible, setPanoVisible] = useState(false)

  const floor = formatPlotName(apartment?.[MAPPED_PROPS.PLOT]).split(".")[0]

  const typeLetter = apartment?.[MAPPED_PROPS.TYPE]
    .split("")
    .pop()
    .toLowerCase()
  const handleSetFavourite = apartment => {
    toggleFavourites(apartment)
  }

  const handleSpecVisible = () => {
    setSpecVisible(!specVisible)
  }

  const handlePanoVisible = () => {
    setPanoVisible(!panoVisible)
  }

  return (
    <Suspense fallback={null}>
      <CardHolder
        className={className}
        onClick={e => {
          e.stopPropagation()
        }}
      >
        <>
          {apartment && (
            <>
              <CardWrapper>
                <CardHeader>
                  <LeftPartHeader>
                    <ApartID>{`Apartment ${
                      apartment?.[MAPPED_PROPS.PLOT]
                    }`}</ApartID>
                    <BedFloorGroup>
                      <BedType>{bedrooms} Bed </BedType>
                      <FloorID>
                        {capitalizeFirstLetter(FLOORS[floor])} Floor
                      </FloorID>
                    </BedFloorGroup>
                  </LeftPartHeader>
                  {!isCompare && !isShare && (
                    <RightPartHeader>
                      <IconButton
                        onClick={() => {
                          setActiveApartment(null)
                        }}
                        icon={MarkerIcon}
                      />
                      <Controls>
                        <IconButton
                          icon={SpecIcon}
                          onClick={handleSpecVisible}
                        />
                        {favVisible && (
                          <>
                            <Divider />
                            <IconButton
                              onClick={() => handleSetFavourite(apartment)}
                              active={isActive}
                              icon={HeartIcon}
                            />
                          </>
                        )}
                      </Controls>
                    </RightPartHeader>
                  )}
                </CardHeader>
                <CardBody>
                  {!isNotMobisPad && !isCompare ? (
                    <>
                      <Compass
                        src={CompassIcon}
                        // style={{ backroundImage: `url(${CompassIcon})` }}
                      />
                      <Preview img="assets/images/apartment/previews/1.jpg" />
                    </>
                  ) : null}
                  {!isNotMobisPad ? (
                    <DataLayout
                      floor={floor}
                      apartment={apartment}
                      typeLetter={typeLetter}
                      isShare={isShare}
                      isNotMobisPad={isNotMobisPad}
                      isCompare={isCompare}
                    />
                  ) : (
                    <MobileDataLayout
                      floor={floor}
                      apartment={apartment}
                      typeLetter={typeLetter}
                      isShare={isShare}
                      isNotMobisPad={isNotMobisPad}
                    />
                  )}
                </CardBody>
              </CardWrapper>
              <AnimatePresence>
                {panoVisible && (
                  <ViewerWraper {...AnimationSettings}>
                    <Viewer360 src="assets/360/sample.JPG" />
                    <BackButton onClick={handlePanoVisible} />
                  </ViewerWraper>
                )}
              </AnimatePresence>
              <AnimatePresence>
                {specVisible && (
                  <ViewerWraper {...AnimationSettings}>
                    <SpecViewer>
                      <img alt="" src={SpecImage} />
                    </SpecViewer>
                    <BackButton color="#000" onClick={handleSpecVisible} />
                  </ViewerWraper>
                )}
              </AnimatePresence>
            </>
          )}
        </>
      </CardHolder>
    </Suspense>
  )
}

function DataLayout({
  apartment,
  isNotMobisPad,
  isShare,
  typeLetter,
  floor,
  isCompare
}) {
  return (
    <Group isNotMobisPad={isNotMobisPad}>
      <Plans style={{ flexDirection: isCompare ? "column" : "row" }}>
        <Plan
          isNotMobisPad={isNotMobisPad}
          src={`assets/apartment/floorplans/type_${typeLetter}_floor_plan.svg`}
        />
        {isCompare && (
          <div
            style={{
              position: "relative",
              left: 0,
              // border: "red solid 2px",
              width: "100%",
              height: "60%"
            }}
          >
            <SvgInfo
              style={{
                backgroundImage: `url(assets/apartment/dims/willow_walk_apartment_dimension_tables_apt_dims_type_${typeLetter}.svg)`
              }}
            />
          </div>
        )}
      </Plans>

      <Info isShare={isShare} isNotMobisPad={isNotMobisPad}>
        {!isCompare && (
          <SvgInfoContainer>
            <SvgInfo
              style={{
                backgroundImage: `url(assets/apartment/dims/willow_walk_apartment_dimension_tables_apt_dims_type_${typeLetter}.svg)`
              }}
            />
          </SvgInfoContainer>
        )}
        <SvgInfoContainer>
          <Pricetable apartment={apartment} />
        </SvgInfoContainer>

        <SvgInfoContainer>
          <TestDiv>
            {!isCompare && (
              <Key
                height="100%"
                style={{
                  backgroundImage:
                    "url(assets/apartment/willow-walk-apartment-key-01.svg)"
                }}
              ></Key>
            )}
            <Floor
              src={`assets/apartment/floor/floor_${floor}_type_${typeLetter}.svg`}
            />
          </TestDiv>
        </SvgInfoContainer>
      </Info>
    </Group>
  )
}

function MobileDataLayout({
  apartment,
  isNotMobisPad,
  isShare,
  typeLetter,
  floor
}) {
  return (
    <GroupMob isNotMobisPad={isNotMobisPad}>
      <MobPlanContainer style={{ flex: 2 }}>
        <Plan
          isNotMobisPad={isNotMobisPad}
          src={`assets/apartment/floorplans/type_${typeLetter}_floor_plan.svg`}
        />
      </MobPlanContainer>
      <MobDimsContainer style={{ flex: 1 }}>
        <SvgInfoMob
          style={{
            backgroundImage: `url(assets/apartment/dims/willow_walk_apartment_dimension_tables_apt_dims_type_${typeLetter}.svg)`
          }}
        />
      </MobDimsContainer>
      <MobPriceContainer style={{ flexShrink: 1 }}>
        <Pricetable apartment={apartment} />
        <FloorMobWrapper>
          <FloorMob
            src={`assets/apartment/floor/floor_${floor}_type_${typeLetter}.svg`}
          />
        </FloorMobWrapper>
      </MobPriceContainer>
    </GroupMob>
  )
}

const GroupMob = styled.div`
  height: 800px;
  padding: 10px;
  /* border: red solid 2px; */
  display: flex;

  flex-direction: column;

  justify-content: space-between;
  width: 100%;
`

const MobPlanContainer = styled.div`
  /* border: blue solid 2px; */
  display: flex;
  justify-content: center;
  align-items: center;
`
const MobPriceContainer = styled.div`
  /* border: blue solid 2px; */
  display: flex;
  flex-direction: row;
`
const MobDimsContainer = styled.div`
  display: flex;
  flex-direction: row;
`
const FloorMobWrapper = styled.div`
  /* border: orange solid 1px; */
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: row;
`

const FloorMob = styled.img`
  flex: 1;
  display: flex;
  max-width: 100px;
  object-fit: contain;
  object-position: bottom right;
`

const SvgInfoMob = styled.div`
  flex: 2;
  background-size: contain;
  background-repeat: no-repeat;
  display: flex;
`

const TestDiv = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  height: 100%;
`

const AnimationSettings = {
  transition: { duration: 0.5 },
  initial: { opacity: 0, y: 20 },
  animate: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: 20 }
}

const Info = styled.div`
  padding: 1vw;
  flex: 2;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  flex-direction: column;
  height: ${props => (props.isShare ? "700" : "")}px;
`

const SvgInfoContainer = styled.div`
  flex: 2;
  position: relative;
  margin: 0;
  padding: 0;
`

const SvgInfo = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  display: flex;
`

const Group = styled.div`
  /* border: red solid 2px; */
  flex: 5;
  display: flex;

  flex-direction: row;
  width: 100%;
`
const Compass = styled.img`
  position: absolute;
  bottom: 2%;
  left: 22%;
  height: 4rem;
  /* border: red solid 2px; */
  width: 4rem;
`
const Floor = styled.img`
  position: absolute;
  right: 0;
  bottom: 0;
  height: 5vw;
  min-height: 100px;
  flex: 1;
  margin: 0;
  object-fit: contain;
  object-position: bottom right;
`
const Key = styled.div`
  /* border: blue solid 2px; */
  position: absolute;
  width: 100%;
  height: 150px;
  background-size: contain;
  background-repeat: no-repeat;
`

const CardHolder = styled.div`
  position: relative;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  background-color: white;
  pointer-events: initial;
  width: 100%;
  height: 100%;
  flex: 1;
  overflow: hidden;
`
const CardWrapper = styled.div`
  /* border: red solid 2px; */
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`
const CardHeader = styled.div`
  max-height: 200px;
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;
  background-color: #bba48b;
  padding: 1em;
`

const LeftPartHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  height: 100%;
  gap: 0.5em;
`

const ApartID = styled.div`
  font-family: DistinctStyleScript;
  font-weight: bold;
  font-size: 4vw;
  letter-spacing: 0.1em;
  color: #0f3e25;
  margin: 0;
  padding: 0;
  padding-left: 1vw;
  @media ${device.pad} {
    font-size: 7vw;
  }
`

const BedFloorGroup = styled.div`
  display: flex;
  gap: 1em;
  padding-left: 2vw;
`

const BedType = styled.p`
  font-family: Brother-1816-Book;
  font-size: 1em;
  line-height: 110%;
  color: #32503d;
  margin: 0;
  padding: 0;
`

const FloorID = styled.p`
  font-family: Brother-1816-Book;
  font-size: 1em;
  line-height: 110%;
  color: #5e7768;
  margin: 0;
  padding: 0;
`

const RightPartHeader = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  gap: 1em;
`

const Controls = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5vw;
`

const Divider = styled.div`
  width: 0.5rem;
  height: 2rem;
  background-image: url(${VDividerIcon});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
`

const CardBody = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  background-color: #fff;
`

const Preview = styled.div`
  width: 20%;
  background-image: url(${props => props.img});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`

const Plans = styled.div`
  padding: 1vw;
  height: 100%;
  flex: 4;
  display: flex;
  padding: 40px;
  justify-content: center;
  align-items: center;
`

const Plan = styled.div`
  height: ${props => (props.isNotMobisPad ? "300px" : "100%")};
  width: ${props => (props.isNotMobisPad ? "300px" : "100%")};

  background-image: url(${props => props.src});
  background-repeat: no-repeat;
  object-fit: scale-down;
  background-position: center;
`

const ViewerWraper = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
`

const SpecViewer = styled.div`
  object-fit: contain;
  width: 100%;
  height: 100%;
  padding: 2em;
  overflow-y: hidden;
  img {
    width: 100%;
    height: 100%;
  }
`

const BackButton = styled(PrevButton)`
  /* background: black; */
  position: absolute;
  top: 1em;
  right: 1em;
  z-index: 1;
  color: black;
`
