export const GalleryData = [
  {
    thumb: "img-00.jpg",
    large: "img-00.jpg",
    title: "South London",
    text: "The new apartments at Willow Walk are an exciting opportunity to make a thriving London neighbourhood your home."
  },
  {
    thumb: "img-01.jpg",
    large: "img-01.jpg",
    title: "Riverside Living",
    text: "Here in the heart of historic Wandsworth, just moments from the riverside and vibrant town centre, Willow Walk offers a close-knit new community in which to weave your own story."
  },
  {
    thumb: "img-02.jpg",
    large: "img-02.jpg",
    title: "Podium Garden",
    text: "Arranged over two buildings, Willow Walk’s contemporary architecture has been carefully designed to blend into and complement its established surroundings through an attractive mix of materials and a range of floor heights, with pedestrianised walkways, leafy courtyards and green terraces below."
  },
  {
    thumb: "img-03.jpg",
    large: "img-03.jpg",
    title: "Central Plaza",
    text: "Within this desirable Peabody development there’s a place for you, with a total of 84 apartments available for Shared Ownership, including 60 two bedroom, 18 one bedroom and six spacious three bedroom apartments.."
  },
  {
    thumb: "img-04.jpg",
    large: "img-04.jpg",
    title: "Living / Kitchen / Dining Space",
    text: null,
    spec: [
      "Matt indigo blue, handleless kitchen units for apartments and maisonettes and underside LED strip lighting to wall units",
      "White quartz worktops with matching splashback and upstands to all homes",
      "Under mounted single bowl stainless steel sink with polished chrome tap",
      "AEG frameless touch ceramic hob with telescopic hood",
      "AEG integrated stainless steel multi-function oven",
      "AEG integrated fridge/freezer",
      "AEG fully integrated dishwasher 3 stage separation integrated recycling bin",
      "Grey oak-effect laminate flooring"
    ]
  },
  {
    thumb: "img-05.jpg",
    large: "img-05.jpg",
    title: "Living / Kitchen / Dining Space",
    text: null,
    spec: [
      "Matt indigo blue, handleless kitchen units for apartments and maisonettes and underside LED strip lighting to wall units",
      "White quartz worktops with matching splashback and upstands to all homes",
      "Under mounted single bowl stainless steel sink with polished chrome tap",
      "AEG frameless touch ceramic hob with telescopic hood",
      "AEG integrated stainless steel multi-function oven",
      "AEG integrated fridge/freezer",
      "AEG fully integrated dishwasher 3 stage separation integrated recycling bin",
      "Grey oak-effect laminate flooring"
    ]
  },
  {
    thumb: "img-06.jpg",
    large: "img-06.jpg",
    title: "Master Bedroom",
    text: null,
    spec: [
      "Cormar grey carpet to all bedrooms with timber thresholds",
      "Pendant lighting to all bedrooms",
      "Built-in wardrobe with sliding stonematt door to main bedrooms"
    ]
  },
  {
    thumb: "img-07.jpg",
    large: "img-07.jpg",
    title: "Residence Bathrooms",
    text: null,
    spec: [
      "Fully tiled grey bathroom with white steel bath and wallmounted chrome overhead hand shower and clear glass bath screen",
      "Full height and widthmirror above white semi-recessed basin with chromemixer tap to all bathrooms and en-suites",
      "Fully tiled grey en-suite with white shower tray and chrome clear glass shower screen and wallmounted chrome overhead hand shower",
      "WC with dual flush chrome push plate to all bathrooms and en-suites Chrome towel radiators to all bathrooms and en-suites"
    ]
  },
  {
    thumb: "img-08.jpg",
    large: "img-08.jpg",
    title: "Secondary Bedroom",
    text: null,
    spec: [
      "Cormar grey carpet to all bedrooms with timber thresholds",
      "Pendant lighting to all bedrooms",
      "Built-in wardrobe with sliding stonematt door to main bedrooms"
    ]
  },
  {
    thumb: "img-09.jpg",
    large: "img-07.jpg",
    title: "Bathroom",
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua"
  }
]
