import LocationSite from "components/LocationSite"
import LocationHighlights from "components/LocationHighlights"
import LocationMap from "components/LocationMap"

export const LOCATION_TAB_DATA = [
  {
    id: 0,
    label: "Willow Walk",
    component: <LocationSite />
  },
  {
    id: 1,
    label: "Local Highlights",
    component: <LocationHighlights />
  },
  {
    id: 2,
    label: "Location",
    component: <LocationMap />
  }
]
