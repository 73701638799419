import { AnimatePresence, motion } from "framer-motion"
import { useStore } from "state/store"
import styled from "styled-components"

import MarkerIcon from "assets/images/icons/marker.svg"
import ListIcon from "assets/images/gallery/list.svg"

export default function GalleryDialog() {
  const openGalleryDialog = useStore(state => state.openGalleryDialog)
  const selectedGallery = useStore(state => state.selectedGallery)
  const setOpenGalleryDialog = useStore(state => state.setOpenGalleryDialog)

  const handleClose = e => {
    e.stopPropagation()
    setOpenGalleryDialog(false)
  }

  return (
    <AnimatePresence>
      {openGalleryDialog && (
        <DialogHolder onClick={handleClose} {...AnimationSettings}>
          <DialogWraper>
            <Image img={`/assets/images/gallery/${selectedGallery?.large}`} />
            <Caption>
              <MarkerImage src={MarkerIcon} />
              <CaptionTitle>{selectedGallery?.title}</CaptionTitle>
              {selectedGallery?.text && (
                <CaptionText>{selectedGallery?.text}</CaptionText>
              )}
              {selectedGallery?.spec && (
                <>
                  <SpecText>Specification</SpecText>
                  <SpecList>
                    {selectedGallery?.spec.map((spec, index) => (
                      <li key={index}>{spec}</li>
                    ))}
                  </SpecList>
                </>
              )}
            </Caption>
          </DialogWraper>
        </DialogHolder>
      )}
    </AnimatePresence>
  )
}

const DialogHolder = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  cursor: pointer;
`
const AnimationSettings = {
  transition: { duration: 0.3 },
  initial: { opacity: 0, y: 20 },
  animate: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: 20 }
}

const DialogWraper = styled.div`
  position: relative;
  width: 90vw;
  max-width: 1400px;
  height: 60vw;
  max-height: 900px;
  background-color: #e5e1e1;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.7);
  display: flex;
  flex-direction: row;
`

const Image = styled.div`
  width: 60%;
  height: 100%;
  background-image: url(${props => props.img});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`

const Caption = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  /* align-items: flex-end; */
  gap: 1vw;
  width: 40%;
  height: 100%;
  padding: 2vw;
`
const CaptionTitle = styled.p`
  font-family: DistinctStyleScript;
  font-size: 2.5vw;
  line-height: 100%;
  color: #1e3422;
  margin: 0;
  padding: 0;
`

const CaptionText = styled.p`
  font-family: Brother-1816-Book;
  font-size: 1vw;
  line-height: 150%;
  color: #1d1d1b;
  margin: 0;
  padding: 0;
  text-align: right;
`
const MarkerImage = styled.img`
  position: absolute;
  top: 2vw;
  right: 2vw;
  z-index: 1;
  width: 3vw;
`
const SpecText = styled.p`
  font-family: LouvetteDeck-Regular;
  font-size: 1.8vw;
  line-height: 130%;
  color: white;
  margin: 0;
  padding: 0;
  padding-left: 1.5vw;
  text-align: left;
`
const SpecList = styled.ul`
  font-family: Brother-1816-Book;
  line-height: 150%;
  margin: 0;
  padding: 0;
  list-style: url(${ListIcon});
  padding-left: 1.5em;
`
