export const HotspotData = [
  [
    {
      pos: { x: 1100, y: 350 },
      title: "A Place to Call Home",
      description:
        "Willow Walk is a revitalising place people are proud to call home and destination in itself. A stunning plaza is the centrepiece of the development with complimentary brickwork and soft landscaping inspired by the historic osier reeds that once prevailed."
    }
  ],
  [
    {
      pos: { x: 1100, y: 350 },
      title: "The Architecture",
      description:
        "Arranged over two buildings, Willow Walk’s contemporary architecture has been carefully designed to blend into and complement its established surroundings through an attractive mix of materials and a range of floor heights, with pedestrianised walkways, leafy courtyards and green terraces below."
    }
  ],
  [
    {
      pos: { x: 1100, y: 350 },
      title: "Stunning Residences",
      description:
        "Within this desirable Peabody development there’s a place for you, with a total of 84 apartments available for Shared Ownership, including 60 two bedroom, 18 one bedroom and six spacious three bedroom apartments."
    }
  ],
  [
    {
      pos: { x: 1600, y: 550 },
      title: "By the Riverside",
      description:
        "Find the London home you’ve longed for amongst Wandsworth’s uplifting riverside scene and green spaces. The new apartments at Willow Walk are an exciting opportunity to make a thriving London neighbourhood your home."
    }
  ],
  [
    {
      pos: { x: 1150, y: 550 },
      title: "Weave your story",
      description:
        "Here in the heart of historic Wandsworth, just moments from the riverside and vibrant town centre, Willow Walk offers a close-knit new community in which to weave your own story."
    }
  ]
]
