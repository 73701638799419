import React from "react"
import styled from "styled-components"
import VideoPlayer from "./VideoPlayer"
import NavButton from "./NavButton"
import { ArrowLeft, ArrowRight } from "./Arrow"
import useConfig from "./useConfig"
import usePairedSegments from "./usePairedSegments"
import HotspotLayer from "./hotspot"
import { useStore } from "state/store"

export default function OrbitManager({ configUrl }) {
  let { loading, config, error } = useConfig(configUrl)

  if (config) {
    return <Orbits segments={config} />
  }

  if (error) {
    return <div>Missing Configuration: {configUrl}</div>
  }

  if (loading) {
    return <div>loading...</div>
  }
}

const OrbitsHolder = styled.div`
  flex: 1;
  display: flex;
`

const PrevButton = styled(NavButton)`
  position: absolute;
  left: 3vw;
  top: 49vh;
  z-index: 2;
  color: white;
`

const NextButton = styled(NavButton)`
  position: absolute;
  right: 3vw;
  top: 49vh;
  z-index: 2;
  color: white;
`

const Orbits = ({ segments }) => {
  const setOpenDialogOrbits = useStore(state => state.setOpenDialogOrbits)
  const setShowHotspots = useStore(state => state.setShowHotspots)
  const orbitsVideoIndex = useStore(state => state.orbitsVideoIndex)
  const isVideoReady = useStore(state => state.isVideoReady)

  const { index, current, left, right, playing, onPlay, onEnd } =
    usePairedSegments(segments, orbitsVideoIndex)

  return (
    <OrbitsHolder>
      <VideoPlayer
        isVisible={true}
        url={current}
        playing={playing === current}
        onEnd={onEnd}
        index={index}
      />
      <HotspotLayer />
      {!playing && isVideoReady ? (
        <>
          <PrevButton
            onClick={() => {
              setOpenDialogOrbits(false)
              setShowHotspots(false)
              onPlay(left)
            }}
          >
            <ArrowLeft />
          </PrevButton>
          <NextButton
            onClick={() => {
              setOpenDialogOrbits(false)
              setShowHotspots(false)
              onPlay(right)
            }}
          >
            <ArrowRight />
          </NextButton>
        </>
      ) : null}
    </OrbitsHolder>
  )
}
