import { memo, useEffect, useMemo, useRef, useState } from "react"
import { useFrame, useThree } from "@react-three/fiber"
import { Billboard, Html, Text } from "@react-three/drei"
import { useGLTF } from "utils/hooks/useGLTF"
import * as THREE from "three"

import { useStore } from "state/store"
import { MAPPED_PROPS } from "api/typeMappers"
import {
  formatPlotName,
  useApartmentsByTypes,
  useAvailableApartments
} from "utils/hooks"
import { helpers, meshNameToPlotId } from "../Utils/helperFunctions"
import FloorsModel from "assets/models/floors.gltf"
import useNotInitialRender from "utils/hooks/useNotInitialRender"

export const FloorPlates = ({ baseState }) => {
  const floors = useFloors()
  const availableApartments = useAvailableApartments()
  const lwActiveFloor = useStore(state => state.lwActiveFloor)

  const [hasInteracted, setHasInteracted] = useState(false)

  useNotInitialRender(() => {
    setHasInteracted(true)
    return () => setHasInteracted(false)
  }, [lwActiveFloor, baseState.top.current])

  return floors.map((floor, i) => (
    <Block
      key={i}
      baseState={baseState}
      floorIndex={i}
      hasInteracted={hasInteracted}
    >
      {floor.map((mesh, index) => (
        <MeshElement
          key={index}
          scale={1}
          mesh={mesh}
          availableApartments={availableApartments}
        />
      ))}
    </Block>
  ))
}

const MATERIALS = {
  reserved: new THREE.MeshStandardMaterial({
    color: "#b9b9b9",
    //side: THREE.DoubleSide,
    flatShading: true
  }),
  hover: new THREE.MeshStandardMaterial({
    color: "#40aa2b",
    //side: THREE.DoubleSide,
    flatShading: true
  }),
  corridor: new THREE.MeshStandardMaterial({
    opacity: 0.7,
    transparent: true,
    color: "#000000",
    //side: THREE.DoubleSide,
    flatShading: true
  }),
  disabled: new THREE.MeshStandardMaterial({
    opacity: 0.1,
    transparent: true,
    color: "#676767",
    //side: THREE.DoubleSide,
    flatShading: true
  }),
  "1": new THREE.MeshStandardMaterial({
    opacity: 1,
    transparent: true,
    color: "#998368",
    //side: THREE.DoubleSide,
    flatShading: true
  }),
  "2": new THREE.MeshStandardMaterial({
    opacity: 1,
    transparent: true,
    color: "#6F7D41",
    //side: THREE.DoubleSide,
    flatShading: true
  }),
  "3": new THREE.MeshStandardMaterial({
    opacity: 1,
    transparent: true,
    color: "#243D27",
    //side: THREE.DoubleSide,
    flatShading: true
  })
}

const MeshElement = memo(function MeshElement({
  mesh,
  scale,
  availableApartments
}) {
  const favourites = useStore(state => state.favourites)
  const setActiveApartment = useStore(state => state.setActiveApartment)

  const { apartmentsByPlot } = useApartmentsByTypes()
  const plotNo = helpers.returnPlotNumber(mesh.name)

  const bedrooms = apartmentsByPlot[plotNo]?.[MAPPED_PROPS.BEDS].charAt(0)
  const isAvailable = apartmentsByPlot[plotNo]?.[MAPPED_PROPS.STATUS] === "0"

  const isApartment = mesh.name.includes("apt")

  const isDisable =
    availableApartments.findIndex(
      apt => formatPlotName(apt["Plot"]) === plotNo
    ) === -1

  const isFavourite =
    favourites.findIndex(
      fav => formatPlotName(fav[MAPPED_PROPS.PLOT]) === plotNo
    ) !== -1
  const material = isApartment
    ? !isAvailable
      ? MATERIALS.reserved
      : isDisable
      ? MATERIALS.disabled
      : isFavourite
      ? MATERIALS.hover
      : MATERIALS[bedrooms]
    : MATERIALS.corridor

  const handlePointerOver = () => {
    document.body.style.cursor = "pointer"
  }
  const handlePointerOut = () => {
    document.body.style.cursor = "auto"
  }
  const handleClick = () => {
    if (isDisable) return

    setActiveApartment(apartmentsByPlot[meshNameToPlotId(mesh.name)])
  }

  return (
    <group rotation={[Math.PI / 2, 0, 0]}>
      {isApartment && (
        <Html
          style={{ pointerEvents: "none" }}
          onClick={handleClick}
          center
          position={[
            mesh.geometry.boundingSphere.center.x,
            mesh.geometry.boundingSphere.center.y,
            mesh.geometry.boundingSphere.center.z
          ]}
        >
          <div
            onClick={handleClick}
            style={{
              cursor: "inherit",
              color: "#f0f0f0",
              fontFamily: "Brother-1816-Book",
              fontSize: "0.7rem",
              width: "max-content",
              textTransform: "uppercase",
              pointerEvents: "none",
              userSelect: "none"
            }}
          >
            {mesh.name.split("-")[1]}-{mesh.name.split("-")[2]}
          </div>
        </Html>
      )}
      <mesh
        name={mesh.name}
        onPointerOver={handlePointerOver}
        onPointerOut={handlePointerOut}
        onClick={handleClick}
        scale={scale}
        geometry={mesh.geometry}
        material={material}
      ></mesh>
    </group>
  )
})

const Block = ({ baseState, children, floorIndex, hasInteracted }) => {
  const { sectionHeight, convert } = useBlock(baseState)

  const ref = useRef()
  let currentPos = useRef()

  const setActiveFloor = useStore(state => state.setActiveFloor)
  const activeFloor = useStore(state => state.activeFloor)
  const apartRotation = useStore(state => state.apartRotation)

  useFrame(() => {
    const curY = ref.current.position.y
    const curTop = baseState.top.current

    ref.current.position.y = THREE.MathUtils.lerp(curY, curTop * convert, 0.1)
    ref.current.rotation.z = apartRotation.theta * -1 + 3.2
    ref.current.rotation.x = -Math.PI / 4 - 0.2

    currentPos.current = curY + -sectionHeight * baseState.factor * floorIndex

    if (
      currentPos.current < (sectionHeight * baseState.factor) / 2 &&
      currentPos.current > -(sectionHeight * baseState.factor) / 2
    ) {
      if (activeFloor !== floorIndex && hasInteracted) {
        setActiveFloor(floorIndex)
      }
    }
  })

  return (
    <group position={[0, -sectionHeight * floorIndex * baseState.factor, 0]}>
      <group name={`Object_${floorIndex}`} ref={ref}>
        {children}
      </group>
    </group>
  )
}

const useBlock = baseState => {
  const { sections, pages } = baseState
  const { viewport } = useThree()

  const viewportWidth = viewport.width
  const viewportHeight = viewport.height
  const canvasWidth = viewportWidth
  const canvasHeight = viewportHeight

  // const convert = 0.01003502748051559
  const convert = (canvasHeight / window.innerHeight) * baseState.factor

  const sectionHeight = canvasHeight * ((pages - 1) / (sections - 1))

  return {
    convert,
    viewport,
    viewportWidth,
    viewportHeight,
    canvasWidth,
    canvasHeight,
    sectionHeight
  }
}

const useFloors = () => {
  const { nodes } = useGLTF(FloorsModel)
  const reducer = (array, val) => {
    return array.filter(element => element.name.split("-")[1] === val)
  }

  //Group Floor
  return useMemo(() => {
    const floors = {}
    const floorIDs = []
    const keys = Object.keys(nodes)
    keys.map(v => floorIDs.push(v.split("-")[1]))
    const aptSet = new Set(floorIDs)
    const nodeList = Object.values(nodes)

    aptSet.forEach(v => (floors[v] = reducer(nodeList, v)))

    return Object.values(floors).reverse()
  }, [nodes])
}
