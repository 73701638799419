import styled from "styled-components"
import { AnimatePresence, motion } from "framer-motion"
import { DndProvider } from "react-dnd"
import { HTML5Backend } from "react-dnd-html5-backend"

import { useStore } from "state/store"

import IconButton from "components/Buttons/IconButton"
import ApartmentCard from "components/ApartmentCard"
import RoomCard from "components/RoomCard"

import CloseIcon from "assets/images/icons/marker.svg"
import { device } from "theme/device"
import { Dustbin } from "./Dustbin"
import { Box } from "./Box"
import { MAPPED_PROPS } from "api/typeMappers"

export const DustbinNames = {
  first: "first",
  second: "second"
}

export default function CompareDialog() {
  const favourites = useStore(state => state.favourites)
  const openCompareDialog = useStore(state => state.openCompareDialog)
  const setOpenCompareDialog = useStore(state => state.setOpenCompareDialog)
  const firstDustbin = useStore(state => state.firstDustbin)

  const secondDustbin = useStore(state => state.secondDustbin)

  return (
    <AnimatePresence>
      {openCompareDialog && (
        <DialogHolder
          transition={{ duration: 0.5 }}
          initial={{ opacity: 0, y: -30 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: 30 }}
        >
          <CloseButton
            icon={CloseIcon}
            onClick={() => setOpenCompareDialog(false)}
          />
          <Content>
            <DndProvider backend={HTML5Backend}>
              <DragHolder>
                <StyledDustbin name={DustbinNames.first}>
                  {firstDustbin !== null ? (
                    <ApartmentCard
                      isCompare={true}
                      apartment={favourites[Number(firstDustbin)]}
                    />
                  ) : (
                    "Click Once to Add"
                  )}
                </StyledDustbin>
                <StyledDustbin name={DustbinNames.second}>
                  {secondDustbin !== null ? (
                    <ApartmentCard
                      isCompare={true}
                      apartment={favourites[Number(secondDustbin)]}
                    />
                  ) : (
                    "Double Click to Add"
                  )}
                </StyledDustbin>
              </DragHolder>
              <RoomList>
                {favourites.map((fav, index) => (
                  <StyledBox key={index} name={index}>
                    <StyledRoomCard
                      apartment={fav}
                      plotNumber={fav[MAPPED_PROPS.PLOT]}
                    />
                  </StyledBox>
                ))}
              </RoomList>
            </DndProvider>
          </Content>
        </DialogHolder>
      )}
    </AnimatePresence>
  )
}

const DialogHolder = styled(motion.div)`
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: #f9f1ec;
`

const CloseButton = styled(IconButton)`
  position: absolute;
  top: 0.5em;
  right: 0.5em;
  z-index: 1;
  width: 3.3em;
  height: 3.3em;
  background-color: transparent;
`

const Content = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  gap: 1em;
  padding: 5em 1em;
  font-family: Futura-Book;
`

const DragHolder = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  gap: 1em;
  @media ${device.pad} {
    flex-direction: column;
  }
`

const StyledDustbin = styled(Dustbin)`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  font-family: LouvetteDeck-Regular;
  color: #697f34;
`

const RoomList = styled.div`
  width: 210px;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 0.3em;
  padding-right: 10px;
`

const StyledBox = styled(Box)`
  width: 200px;
  height: 400px;
`

const StyledRoomCard = styled(RoomCard)`
  cursor: pointer;
  width: 200px !important;
  height: 400px !important;
`
