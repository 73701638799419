import React, { useEffect } from "react"
import { Link, useHistory, useLocation } from "react-router-dom"

/**
 * A wrapper around react-router-dom Link component which delays the route.
 * Useful when we want to wait for the navigation transition or animation to finish before routing to a different location.
 *
 * @param {number} delay Milliseconds before registering the click.
 * @param {function} onDelayStart Called after the link is clicked and before the delay timer starts.
 * @param {function} onDelayEnd Called after the delay timer ends.
 * @param {boolean} replace Replace history or not
 * @param {string} to Link to go to
 * @param {*} ...rest Rest of the react router dom Link props.
 * @returns react-router-dom Link
 */
export default function DelayLink({
  delay,
  onDelayStart,
  onDelayEnd,
  replace,
  to,
  ...rest
}) {
  let timeout = null
  let history = useHistory()
  let location = useLocation()

  useEffect(() => {
    return () => {
      if (timeout) {
        clearTimeout(timeout)
      }
    }
  }, [timeout])

  const handleClick = e => {
    if (location?.pathname === to) return

    onDelayStart(e, to)

    if (e.defaultPrevented) {
      return
    }

    e.preventDefault()

    timeout = setTimeout(() => {
      if (replace) history.replace(to)
      else history.push(to)

      onDelayEnd(e, to)
    }, delay)
  }

  return <Link {...rest} to={to} onClick={handleClick} />
}
